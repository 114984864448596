export const valueTransform = (val) => {
  if (val && val !== 'undefined') {
    return val.toString();
  } else {
    return "";
  }
};

export const GetApprovalByPositionName = (props, name) => {
  if (props) {
    for (let index = 0; index < props.ApprovalStep.length; index++) {
      const step = props.ApprovalStep[index];
      if (step.ApprovalTemplate.Name === name) {
        return step;
      }
    }
  }
  return null;
};
export const GetApprovalByPositionCode = (props, code) => {
  if (props) {
    for (let index = 0; index < props.ApprovalStep.length; index++) {
      const step = props.ApprovalStep[index];
      if (step.ApprovalTemplate.Code === code) {
        return step;
      }
    }
  }
  return null;
};
export const fullNameTransform = (props, who = "self") => {
  if (who === "advisor") {
    return (
      valueTransform(props?.DataUser?.ReportTo?.ThaiPrename) + " "+
      valueTransform(props?.DataUser?.ReportTo?.FirstnameTh) +
      " " +
      valueTransform(props?.DataUser?.ReportTo?.LastnameTh)
    );
  } else {
    return (
      valueTransform(props?.DataUser?.ThaiPrename) + " "+
      valueTransform(props?.DataUser?.FirstnameTh) +
      " " +
      valueTransform(props?.DataUser?.LastnameTh)
    );
  }
};

export const addressTransform = (props) => {
  if (props.DataUser && props.DataUser.CurrentAddress) {
    return (
      valueTransform(props.DataUser.CurrentAddress?.HomeNo) +
      " " +
      valueTransform(props.DataUser.CurrentAddress?.Soi) +
      " " +
      valueTransform(props.DataUser.CurrentAddress?.VillageNo) +
      " " +
      valueTransform(props.DataUser.CurrentAddress?.City) +
      " " +
      valueTransform(props.DataUser.CurrentAddress?.Province) +
      " " +
      valueTransform(props.DataUser.CurrentAddress?.Country) +
      " " +
      valueTransform(props.DataUser.CurrentAddress?.Zipcode)
    );
  }
  return "";
};
