import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import LoginPage from "./features/Login/LoginPage";
import "./styles/global.scss";
import EditProfilePage from "./features/EditProfile/EditProfilePage";
import RequestFormMain from "./features/RequestForm/RequestFormMain";
import MainPage from "./features/Main/MainPage";
import ListDocumentStudent from "./features/ListDocumentStudent/ListDocumentStudent";
import ListDocument from "./features/ListDocument/ListDocument";

import ProfilePage from "./features/Profile/ProfilePage";
import { Header } from "antd/lib/layout/layout";
import HeaderApp from "./features/Header/HeaderApp";
import FormExportMain from "./features/RequestFormExport/FormExportMain";
import FormExportMainPreview from "./features/RequestFormExport/FormExportMainPreview";
import DashboardPage from "./features/Dashboard/Dashboard";
import ListDocumentTeacher from "./features/ListDocumentTeacher/ListDocumentTeacher";
import LoginTeacherPage from "./features/LoginTeacher/LoginTeacherPage";
import TeacherApprove from "./features/TeacherApprove/TeacherApprove";
import LandingPage from "./features/Landing/LandingPage";
import HTTP401Page from "./features/HttpStatusPage/401page";
import HTTP403Page from "./features/HttpStatusPage/403page";

function App() {
  return (
    <Layout className={"main-layout"}>
      <Layout
        className={
          window.location.pathname !== "/login" &&
            window.location.pathname !== "/login/teacher"
            ? "layout-custom"
            : ""
        }
      >
        {window.location.pathname !== "/login" &&
          window.location.pathname !== "/login/teacher" &&
          window.location.pathname !== "/" &&
          window.location.pathname !== "/401-page" &&
          window.location.pathname !== "/403-page" ? (
          <Header className="custom-header">
            <HeaderApp />
          </Header>
        ) : (
          ""
        )}
        <Content className="content-custom">
          <Routes>
            <Route path="/" element={<LandingPage />}></Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login/teacher" element={<LoginTeacherPage />} />
            <Route path="/edit-profile" element={<EditProfilePage />}></Route>
            <Route path="/request-form" element={<RequestFormMain />} />
            <Route path="/main" element={<MainPage />}></Route>
            <Route
              path="/list-document-student"
              element={<ListDocumentStudent />}
            ></Route>
            <Route path="/profile" element={<ProfilePage />}></Route>
            <Route path="/dashboard" element={<DashboardPage />}></Route>
            <Route path="/form-pdf" element={<FormExportMain />} />
            <Route
              path="/form-pdf-preview"
              element={<FormExportMainPreview />}
            />
            <Route
              path="/list-document"
              element={<ListDocument />}
            ></Route>
            <Route
              path="/list-document-teacher"
              element={<ListDocumentTeacher />}
            ></Route>
            <Route
              path="/list-document-teacher-approved"
              element={<ListDocumentTeacher approved={true} />}
            ></Route>
            <Route path="/approve" element={<TeacherApprove />} />
            <Route path="/401-page" element={<HTTP401Page></HTTP401Page>}></Route>
            <Route path="/403-page" element={<HTTP403Page></HTTP403Page>}></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
