import "jspdf-autotable";

import {
  GetApprovalByPositionCode,
  GetApprovalByPositionName,
  fullNameTransform,
  valueTransform,
} from "../../util/ValueTransform";
import { thaitext, thaitextCenter,thaitextReasonMove } from "../../util/Thaitext";

import checkMark from "../../assets/image/checkmark.png";
import { dateSignature } from "../../util/DateSignature";
import jsPDF from "jspdf";
import kuLogo from "../../assets/image/logo/ku_logo.jpeg";
import qrCode from "../../assets/image/qrcode/form-example.jpg";
import sarabunFont from "../../assets/fonts/sarabun/THSarabunNew.ttf";
import sarabunFontMedium from "../../assets/fonts/sarabun/Sarabun-Medium.ttf";
import sarabunLight from "../../assets/fonts/sarabun/Sarabun-Light.ttf";
import sarabunRegular from "../../assets/fonts/sarabun/Sarabun-Regular.ttf";
import { thaiDate } from "../../util/DateObject";

export const Form4 = (props) => {
  const doc = new jsPDF({
    orientation: "p",
    format: "a4",
  });

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.addFont(sarabunLight, "Sarabun-Light", "normal");

  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.addImage(
    kuLogo,
    "JPEG",
    doc.internal.pageSize.getWidth() / 2 - 12,
    5,
    25,
    25,
    0,
    "NONE",
    0
  );
  doc.text(
    "มหาวิทยาลัยเกษตรศาสตร์",
    doc.internal.pageSize.getWidth() / 2 - 25,
    40
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(12);
  console.log(" props?.Data?.form_topic", props?.Data?.form_topic);

  if (props?.Data?.form_topic === 1) {
    thaitext(
      doc,
      "ใบคำร้องทั่วไป",
      doc.internal.pageSize.getWidth() / 2 - 10,
      47
    );
  } else {
    thaitext(
      doc,
      "ใบคำร้องทั่วไป วิทยาศาสตร์เกษตร",
      doc.internal.pageSize.getWidth() / 2 - 26,
      47
    );
  }

  //Righr QRcode
  doc.addImage(
    qrCode,
    "JPEG",
    doc.internal.pageSize.getWidth() / 2 + 70,
    5,
    30,
    30,
    "qr-code",
    "NONE",
    0
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ตัวอย่างการเขียนคำร้อง",
    doc.internal.pageSize.getWidth() / 2 + 70,
    40
  );
  //End Right QRcode

  // Contect
  doc.setFontSize(10);
  thaitext(
    doc,
    "วันที่........เดือน..................................พ.ศ.................",
    doc.internal.pageSize.getWidth() / 2 - 35,
    54
  );
  doc.setFontSize(9);
  doc.setFont("Sarabun-Regular");
  thaitext(
    doc,
    `${thaiDate(props?.DatetimeCreated).day}`,
    doc.internal.pageSize.getWidth() / 2 - 27,
    53
  );
  thaitext(
    doc,
    `${thaiDate(props?.DatetimeCreated).month}`,
    doc.internal.pageSize.getWidth() / 2 - 5,
    53
  );
  thaitext(
    doc,
    `${thaiDate(props?.DatetimeCreated).year}`,
    doc.internal.pageSize.getWidth() / 2 + 25,
    53
  );

  thaitext(
    doc,
    "เรื่อง.................................................................",
    10,
    65
  );

  doc.setFontSize(9);
  doc.setFont("Sarabun-Regular");

  thaitext(
    doc,
    props?.Data?.form_topic === 2 ? "ขอย้ายแขนงวิชา" : "ขอย้ายสาขาวิชา",
    25,
    64
  );

  thaitext(
    doc,
    "เรียน.................................................................",
    10,
    73
  );
  doc.setFontSize(9);
  doc.setFont("Sarabun-Regular");
  thaitext(doc, "(อาจารย์ที่ปรึกษา)", 25, 78);

  thaitext(
    doc,
    `${props.DataUser?.ReportTo?.FirstnameTh} ${props.DataUser?.ReportTo?.LastnameTh}`,
    25,
    72
  );

  doc.setFontSize(9);
  doc.setFont("Sarabun-Regular");
  thaitext(
    doc,
    "ข้าพเจ้า..............................................................................................................................เลขประจำตัว.................................. นิสิตปีที่ .............",
    20,
    85
  );

  thaitext(
    doc,
    `${props?.DataUser?.ThaiPrename} ${props?.DataUser?.FirstnameTh} ${props?.DataUser?.LastnameTh}`,
    35,
    84
  );
  thaitext(doc, `${props?.DataUser?.Code}`, 154, 84);
  thaitext(doc, `${props?.DataUser?.AcademicYear}`, 191, 84);

  thaitext(
    doc,
    "สาขาวิชาเอก.................................................................................................คณะ.............................................................................................................",
    10,
    92
  );
  thaitext(
    doc,
    `${
      props?.Data?.form_topic === 1
        ? props?.Data?.form_old_department
        : props?.Data?.form_old_major
    }`,
    35,
    91
  );
  thaitext(doc, `${props.DataUser.Faculty}`, 130, 91);

  thaitext(
    doc,
    "ที่อยู่ปัจจุบัน.......................................................................................................................................................................................................................",
    10,
    99
  );

  thaitext(
    doc,
    `${props.DataUser?.CurrentAddress?.HomeNo} ${props.DataUser?.CurrentAddress?.Soi} ต.${props.DataUser?.CurrentAddress?.VillageNo} อ.${props.DataUser?.CurrentAddress?.City} จ.${props.DataUser?.CurrentAddress?.Province} ${props.DataUser?.CurrentAddress?.Country} ${props.DataUser?.CurrentAddress?.Zipcode}`,
    35,
    98
  );

  thaitext(
    doc,
    "โทรศัพท์........................................................................................E-mail.........................................................................................................................",
    10,
    106
  );
  thaitext(doc, `${props.DataUser?.Phonenumber}`, 35, 105);
  thaitext(doc, `${props.DataUser?.Email}`, 120, 105);

  thaitext(
    doc,
    "ประสงค์ขอย้ายไปเรียน สาขาวิชา/หลักสูตร.....................................................................................",
    10,
    113
  );
  thaitext(
    doc,
    `${
      props.Data.form_topic === 1
        ? props.Data?.form_new_department
        : props.Data?.form_new_major
    }`,
    68,
    112
  );

  // doc.text(
  //   "ประโยคความซ้อน (สังกรประโยค) หมายถึง ประโยคที่รวมประโยคความเดียว ๑ ประโยคเป็นประโยคหลัก แล้วมีประโยคความเดียวอื่นมาเสริม มีข้อสังเกตคือ ประโยคหลัก (มุขยประโยค) กับ ประโยคย่อย (อนุประโยค) ของประโยคความช้อนมี น้ำหนังไม่เท่ากัน",
  //   10,
  //   119,
  //   { maxWidth: 180 }
  // );

  //
  // var splitTitle = doc.splitTextToSize(
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheet",
  //   180
  // );
  // doc.text(10, 119, splitTitle);
  //

  thaitext(
    doc,
    "เนื่องจาก.................................................................................................................................................................................................................",
    20,
    120
  );
  //thaitext(doc, "เนื่องจาก", 20, 119);
  thaitextReasonMove(doc, `${props.Data?.form_reason}`, 37, 119);

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................................",
    10,
    127
  );

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................................",
    10,
    134
  );

  thaitext(doc, "จึงเรียนมาเพื่อโปรดพิจารณา", 20, 141);
  thaitext(
    doc,
    "ลงนาม....................................................",
    140,
    148
  );
  doc.addImage(
    valueTransform(props.ApprovalStep[0]?.SignatureImage),
    "JPEG",
    167,
    143,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature-step-4",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(                                                              )",
    150,
    155
  );
  thaitextCenter(
    doc,
    `${props.DataUser.ThaiPrename} ${props.DataUser.FirstnameTh} ${props.DataUser.LastnameTh}`,
    168,
    155
  );

  doc.setFont("Sarabun-Light");
  doc.rect(10, 161, 95.25, 43);
  doc.rect(105.25, 161, 95.25, 43);
  doc.rect(10, 204, 95.25, 43);
  doc.rect(105.25, 204, 95.25, 43);
  doc.rect(10, 247, 95.25, 43);
  doc.rect(105.25, 247, 95.25, 43);

  // text in boxes
  // Start box 1
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "1. เรียน หัวหน้าภาควิชา", 15, 167);

  thaitext(doc, "เห็นควรอนุญาต", 30, 180);

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    187
  );

  doc.addImage(
    valueTransform(props.ApprovalStep[1]?.SignatureImage),
    "JPEG",
    70,
    182,
    valueTransform(props.ApprovalStep[1]?.SignatureDimension?.width),
    5,
    "signature-step-1-1",
    "NONE",
    0
  );

  thaitext(doc, "(.................................................)", 55, 192);
  thaitext(doc, fullNameTransform(props, "advisor"), 57, 191);
  thaitext(doc, "........./.........../..............", 65, 197);
  thaitext(
    doc,
    `${
      props.ApprovalStep[1]
        ? dateSignature(
            props.ApprovalStep[1].DatetimeUpdated
              ? props.ApprovalStep[1]?.DatetimeUpdated
              : props.ApprovalStep[1].DatetimeCreated
          )
        : ""
    }`,
    65,
    196
  );
  thaitext(doc, "อาจารย์ที่ปรึกษา", 65, 202);
  // End Box 1

  // Start Box 3
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, `4. เรียน คณบดี`, 110, 166);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "เพื่อโปรดพิจารณาอนุมัติ", 140, 176);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    187
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionCode(props, "chiefDepartment")?.SignatureImage
    ),
    "JPEG",
    165,
    182,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefDepartment")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-1-4",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    192
  );
  thaitext(
    doc,
    valueTransform(
      `${
        props.Data.form_topic === 1
          ? GetApprovalByPositionCode(props, "chiefDepartment")?.ForDev
              ?.Department?.Chief
          : GetApprovalByPositionCode(props, "chiefDepartment")?.ForDev
              ?.Department?.Chief //NOTEEVE Major
      }`
    ),
    152,
    191
  );
  thaitext(doc, "........./.........../..............", 158, 197);
  thaitext(
    doc,
    `${
      props.ApprovalStep[4]
        ? dateSignature(
            props.ApprovalStep[4].DatetimeUpdated
              ? props.ApprovalStep[4]?.DatetimeUpdated
              : props.ApprovalStep[4].DatetimeCreated
          )
        : ""
    }`,
    158,
    196
  );
  thaitext(doc, "ประธานบริหารหลักสูตรฯ", 155, 202);
  // End Box 3

  // Start Box 2
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "2. เรียน หัวหน้าภาควิชา.........................................................................",
    15,
    211
  );

  thaitext(
    doc,
    `${
      props.Data.form_topic === 1
        ? props.Data?.form_new_department
        : props.Data?.form_new_major
    }`,
    50,
    210
  );

  thaitext(doc, "(ภาคฯ/หลักสูตร ที่นิสิตขอย้ายเข้า)", 50, 215);
  thaitext(doc, "เพื่อโปรดพิจารณา", 60, 224.5);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    230
  );

  doc.addImage(
    valueTransform(props.ApprovalStep[2]?.SignatureImage),
    "JPEG",
    70,
    225,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabusOld")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-1-2 ",
    "NONE",
    0
  );
  thaitext(doc, "(.................................................)", 55, 235);
  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabusOld")?.ForDev?.Department
        ?.Chief
    ),
    57,
    234
  );
  thaitext(doc, "........./.........../..............", 65, 240);
  thaitext(
    doc,
    `${
      props.ApprovalStep[2]
        ? dateSignature(
            props.ApprovalStep[2].DatetimeUpdated
              ? props.ApprovalStep[2]?.DatetimeUpdated
              : props.ApprovalStep[2].DatetimeCreated
          )
        : ""
    }`,
    65,
    239
  );
  thaitext(doc, "หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ(เดิม)", 45, 245);
  // End Box 2

  // Start Box 4
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "5. เรียน คณบดี", 110, 209);
  thaitext(
    doc,
    "เพื่อโปรดพิจารณาเสนอที่ประชุมคณะกรรมการประจำคณะเกษตร",
    113,
    215
  );
  thaitext(doc, "พิจารณาให้ความเห็นชอบต่อไป", 137, 220);

  doc.setFont("Sarabun-Light");
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    230
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionCode(props, "studyOfficer")?.SignatureImage
    ),
    "JPEG",
    168,
    225,
    valueTransform(
      GetApprovalByPositionCode(props, "studyOfficer")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-1-5",
    "NONE",
    0
  );

  thaitext(
    doc,
    "(.................................................)",
    150,
    235
  );
  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "studyOfficer")?.ForDev?.ApprovedBy
    ),
    152,
    234
  );
  thaitext(doc, "........./.........../..............", 160, 240);
  thaitext(
    doc,
    `${
      props.ApprovalStep[5]
        ? dateSignature(
            props.ApprovalStep[5].DatetimeUpdated
              ? props.ApprovalStep[5]?.DatetimeUpdated
              : props.ApprovalStep[5].DatetimeCreated
          )
        : ""
    }`,
    160,
    239
  );
  thaitext(doc, "เจ้าหน้าที่ฝ่ายการศึกษา", 157, 245);

  // End Box 4

  // Start Box 7
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "3. เรียน ประธานบริหารหลักสูตรฯ........................................................",
    15,
    252
  );
  thaitext(
    doc,
    `${
      props.Data.form_topic === 1 //NOTEEVE 2
        ? props.Data?.form_old_department
        : "วิทยาศาสตร์เกษตร"
    }`,
    65,
    251
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  doc.rect(20, 255, 3, 3);

  thaitext(
    doc,
    "ไม่ขัดข้องและจัดให้................................................................",
    25,
    258
  );

  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabusNew")?.ForDev?.advisorName
    ),
    55,
    257
  );

  thaitext(doc, "รหัส A..................เป็นอาจารย์ที่ปรึกษา", 25, 263);
  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabusNew")?.ForDev?.advisorCode
    ),
    35,
    262
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);

  doc.rect(20, 266, 3, 3);

  thaitext(doc, "ไม่สามารถรับได้", 25, 269);

  if (
    GetApprovalByPositionCode(props, "chiefSyllabusNew")?.Action === "APPROVED"
  ) {
    doc.addImage(
      checkMark,
      "PNG",
      20,
      253,
      5,
      5,
      "signature-step-checkmark1",
      "NONE",
      0
    );
  } else if (
    GetApprovalByPositionCode(props, "chiefSyllabusNew")?.Action === "REJECTED"
  ) {
    doc.addImage(
      checkMark,
      "PNG",
      20,
      264,
      5,
      5,
      "signature-step-checkmark1",
      "NONE",
      0
    );
  }
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    273
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabusNew")?.SignatureImage
    ),
    "JPEG",
    70,
    267,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabusNew")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-1-3 ",
    "NONE",
    0
  );

  thaitext(doc, "(.................................................)", 55, 278);
  thaitext(
    doc,
    valueTransform(
      `${
        props.Data.form_topic === 1
          ? GetApprovalByPositionCode(props, "chiefSyllabusNew")?.ForDev
              ?.Department?.Chief
          : GetApprovalByPositionCode(props, "chiefSyllabusNew")?.ForDev
              ?.Department?.Chief //NOTEEVE Major
      }`
    ),
    57,
    277
  );
  thaitext(doc, "........./.........../..............", 65, 283);
  thaitext(
    doc,
    `${
      GetApprovalByPositionCode(props, "chiefSyllabusNew")
        ? dateSignature(
            GetApprovalByPositionCode(props, "chiefSyllabusNew").DatetimeUpdated
              ? GetApprovalByPositionCode(props, "chiefSyllabusNew")
                  ?.DatetimeUpdated
              : GetApprovalByPositionCode(props, "chiefSyllabusNew")
                  .DatetimeCreated
          )
        : ""
    }`,
    65,
    282
  );
  // End Box 7

  // Start Box 8
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "6. เรียน เลขานุการคณะกรรมการประจำคณะเกษตร", 110, 252);

  thaitext(
    doc,
    "เพื่อโปรดพิจารณาเสนอที่ประชุมคณะกรรมการประจำคณะเกษตร",
    113,
    258
  );
  thaitext(doc, "พิจารณาให้ความเห็นชอบต่อไป", 137, 263);

  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    273
  );
  doc.addImage(
    valueTransform(GetApprovalByPositionCode(props, "dean")?.SignatureImage),
    "JPEG",
    168,
    267,
    valueTransform(
      GetApprovalByPositionCode(props, "dean")?.SignatureDimension?.width
    ),
    5,
    "signature-step-4-6",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    278
  );

  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "dean")?.ApprovalTemplate.FullName
    ),
    152,
    277
  );
  thaitext(doc, "........./.........../..............", 160, 283);
  thaitext(
    doc,
    `${
      props.ApprovalStep[6]
        ? dateSignature(
            props.ApprovalStep[6].DatetimeUpdated
              ? props.ApprovalStep[6]?.DatetimeUpdated
              : props.ApprovalStep[6].DatetimeCreated
          )
        : ""
    }`,
    160,
    282
  );
  thaitext(doc, "คณบดี", 168, 288);
  // thaitext(doc, "รองคณบดีฝ่ายวิชาการ", 158, 288);
  // thaitext(doc, "ปฏิบัติหน้าที่แทนคณบดีคณะเกษตร", 148, 295);

  doc.output("dataurlnewwindow");
  // doc.save("form1.pdf");
};
