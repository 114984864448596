import "./style.scss";

/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, Modal, Row, Spin, Steps, notification } from "antd";
import React, { useEffect, useState } from "react";

import ApiClient from "../../../shared/api/ApiClient";
import { Form1 } from "../../RequestFormExport/FormKU1";
import { Form2 } from "../../RequestFormExport/FormKU3";
import { Form3 } from "../../RequestFormExport/FormGeneral";
import { Form4 } from "../../RequestFormExport/FormMove";
import { Form5 } from "../../RequestFormExport/FormOff";
import { Form6 } from "../../RequestFormExport/FormLeave";
import { Form7 } from "../../RequestFormExport/FormBreak";
import ICONCHECKED from "../../../assets/image/logo/icon-checked.svg";
import ICONPENDING from "../../../assets/image/logo/icon-pending.svg";
import ICONSETTING from "../../../assets/image/logo/icon-setting.svg";
import CANCELPENDING from "../../../assets/image/logo/icon-cancel.svg";
import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";

dayjs.extend(customParseFormat).extend(buddhistEra);

const { Step } = Steps;

export default function ModalViewStatusReportDocument(props) {
  const [transectionDetail, setTransectionDetail] = useState();

  useEffect(() => {
    if (props.visible === true) {
      callServiceGetTransectionDetail(props.transectionId);
    }
  }, [props]);
  async function callServiceGetTransectionDetail(id) {
    if (id) {
      try {
        let result = await (
          await ApiClient.get(
            `${process.env.REACT_APP_API_HOST}/formTransactions/${id}/detail`
          )
        ).data;
        setTransectionDetail(result);
      } catch (error) {
        setTransectionDetail(null);
        notification.error({
          message: `Error`,
          description: `Something went wrong !`,
        });
      }
    }
  }
  function getSignDateTime(transectionDetail) {
    let item = {};
    for (let index = transectionDetail?.ApprovalStep.length - 1; index > -1; index--) {
      const element = transectionDetail?.ApprovalStep[index];
      if (element.Action === 'APPROVED' || element.ApprovalTemplate.StepNumber === 0) {
        item = element
        break;
      }
    }
    return item.DatetimeUpdated ? dayjs(new Date(item.DatetimeUpdated)).locale(localeTh).format("DD MMM BBBB เวลา HH:mm") : dayjs(new Date(item.DatetimeCreated)).locale(localeTh).format("DD MMM BBBB เวลา HH:mm");
  }
  return (
    <Modal
      className="warp-modal-view-status-document-student"
      destroyOnClose={true}
      onCancel={() => {
        props.closeModal();
        setTransectionDetail(null);
      }}
      visible={props?.visible}
      width={750}
      footer={<div></div>}
    >
      {transectionDetail ? (
        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
          <Col md={14}>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">นิสิตผู้ยื่นคำร้อง</p>
              </Col>
              <Col md={14}>
                <p className="wrap-txt-detail">{`${transectionDetail?.DataUser?.ThaiPrename} ${transectionDetail?.DataUser?.FirstnameTh} ${transectionDetail?.DataUser?.LastnameTh}`}</p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">ชื่อคำร้อง</p>
              </Col>
              <Col md={14}>
                {(() => {
                  if (transectionDetail.FormType.Id === 4) {
                    return (
                      <p className="wrap-txt-detail">
                        {transectionDetail.Data.form_topic === 1 ? 'ขอย้ายสาขาวิชา' : 'ขอย้ายแขนงวิชา'}
                      </p>
                    )
                  } else if (transectionDetail.FormType.Id === 5) {
                    return (<p className="wrap-txt-detail">
                      {transectionDetail.Data.form_topic === 1 ? 'ลาป่วย' : 'ลากิจ'}
                    </p>)
                  } else {
                    return (<p className="wrap-txt-detail">

                      {transectionDetail.Data.form_topic ?? transectionDetail.FormType.Name}
                    </p>)
                  }

                  return null;
                })()}

              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">สถานะล่าสุด</p>
              </Col>
              <Col md={14}>
                <p className="wrap-txt-detail">
                {getSignDateTime(transectionDetail)}   
                        </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">สถานะการจัดการ</p>
              </Col>
              
              <Col md={14}>
                <p className={`wrap-txt-detail ${props?.dataProps?.Action}`}>
                  {props?.dataProps?.Action === "PENDING"
                    ? "ยังไม่ได้ลงนาม"
                    : ""}

                  {props?.dataProps?.Action === "APPROVED"
                    ? "ลงนามเรียบร้อยแล้ว"
                    : ""}

                  {props?.dataProps?.Action === "REJECTED"
                    ? "เอกสารไม่ผ่านการพิจารณา"
                    : ''}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
            <Col md={8}>
                <p className="wrap-txt-section">เอกสารแนบ</p>
              </Col>
              <Col md={14}>
                {transectionDetail.FormFile && transectionDetail.FormFile.length !== 0 ?
                  <a className="form-file-modal-student" target={"_blank"} href={`${process.env.REACT_APP_API_HOST}${transectionDetail.FormFile[0].Url}`} rel="noreferrer">{transectionDetail.FormFile[0].Name}</a>
                  : ""
                }
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={24}>
                {props?.dataProps?.statusDocument === "pending" ? (
                  <Button className="modal-view-status-btn-preview-document">
                    <img
                      src={ICONSETTING}
                      alt="icon-setting"
                      style={{ margin: "0px 5px" }}
                      width="18"
                      height="18"
                    ></img>
                    จัดการคำร้อง
                  </Button>
                ) : (
                  <Button
                    className="modal-view-status-btn-preview-document"
                    onClick={async () => {
                      if (transectionDetail.FormType.Code === "KU1") {
                        Form1(transectionDetail);
                      } else if (transectionDetail.FormType.Code === "KU3") {
                        Form2(transectionDetail);
                      } else if (transectionDetail.FormType.Code === "GEN") {
                        Form3(transectionDetail);
                      } else if (transectionDetail.FormType.Code === "MOVE") {
                        Form4(transectionDetail);
                      } else if (transectionDetail.FormType.Code === "OFF") {
                        Form5(transectionDetail);
                      } else if (transectionDetail.FormType.Code === "LEAVE") {
                        Form6(transectionDetail);
                      } else if (transectionDetail.FormType.Code === "BREAK") {
                        Form7(transectionDetail);
                      } else {
                        return;
                      }
                    }}
                  >
                    ดูเอกสารคำร้อง
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={10}>
            <Steps direction="vertical" current={null}>
              {transectionDetail.ApprovalStep.map((x) => {
                return x.ApprovalTemplate.Code !== "officeAdmin" &&
                  x.Action != "NONE" ? (
                  <Step
                    title=
                    {(() => {

                      if (x.ApprovalTemplate.Name == "อาจารย์ประจำวิชา") {
                        return (
                          `${x.ApprovalTemplate.Name}`
                        )
                      } else if (x.ApprovalTemplate.Name == "หัวหน้าภาคฯ / ประธานบริหาร" && x.ApprovalTemplate.FormTypeId !== 5) {
                        return ("หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ")
                      } else if (x.ApprovalTemplate.FormTypeId == 5 && transectionDetail.Data.form_date < 15 && x.ApprovalTemplate.StepNumber > 1) {
                        return ''
                      } else if (x.ApprovalTemplate.Name == "หัวหน้าภาคฯ / ประธานบริหาร"){
                        return ("หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ")   
                      }
                      else if (x.ApprovalTemplate.Name == "ประธานบริหารหลักสูตรใหม่" && x.ApprovalTemplate.FormTypeId ==4) {
                        return ("ประธานบริหารหลักสูตรฯใหม่")}
                        else if (x.ApprovalTemplate.Name == "ประธานบริหารหลักสูตรฯ" && x.ApprovalTemplate.FormTypeId ==4) {
                          return ("ประธานบริหารหลักสูตรฯเดิม")}
                           else {
                        return (x.ApprovalTemplate.Name)
                      }
                      return null;
                    })()}
                    icon={
                      x.Action ==="PENDING" && x.ApprovalTemplate.FormTypeId===5 && transectionDetail.Data.form_date < 15&& x.ApprovalTemplate.StepNumber >1? (
                        <></>
                       ) : 
                      x.Action === "APPROVED" ? (
                        <img
                          src={ICONCHECKED}
                          width={32}
                          height={32}
                          alt="icon-check"
                        ></img>
                      ) : x.Action === "REJECTED" ? (
                        <img
                          src={CANCELPENDING}
                          width={32}
                          height={32}
                          alt="icon-pending"
                        ></img>
                      ) : (
                        <img
                          src={ICONPENDING}
                          width={32}
                          height={32}
                          alt="icon-pending"
                        ></img>
                      )
                    }
                  />
                ) : (
                  <></>
                );
              })}
            </Steps>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <Spin />
        </Row>
      )}
    </Modal>
  );
}
