import "jspdf-autotable";

import {
  GetApprovalByPositionName,
  GetApprovalByPositionCode,
  fullNameTransform,
  valueTransform,
} from "../../util/ValueTransform";

import { dateSignature } from "../../util/DateSignature";
import jsPDF from "jspdf";
import kuLogo from "../../assets/image/logo/ku_logo.jpeg";
import qrCode from "../../assets/image/qrcode/form-example.jpg";
import sarabunFont from "../../assets/fonts/sarabun/THSarabunNew.ttf";
import sarabunFontMedium from "../../assets/fonts/sarabun/Sarabun-Medium.ttf";
import sarabunLight from "../../assets/fonts/sarabun/Sarabun-Light.ttf";
import sarabunRegular from "../../assets/fonts/sarabun/Sarabun-Regular.ttf";
import { thaiDate } from "../../util/DateObject";
import { thaitext } from "../../util/Thaitext";

export const Form1 = (props) => {
  console.log("props", props);
  const doc = new jsPDF({
    orientation: "p",
    format: "a4",
  });

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.rect(5, 5, doc.internal.pageSize.width - 10, 25, "S");
  doc.addImage(kuLogo, "JPEG", 30, 8, 20, 20, 0, "NONE", 0);

  doc.text(
    "มหาวิทยาลัยเกษตรศาสตร์แบบลงทะเบียนเรียน (KU1)",
    doc.internal.pageSize.getWidth() / 2 + 5,
    15,
    { align: "center" }
  );

  doc.text(
    "KASETSART UNIVERSITY REGISTRATION FORM",
    doc.internal.pageSize.getWidth() / 2 + 5,
    23,
    { align: "center" }
  );

  doc.rect(5, 5, doc.internal.pageSize.width - 10, 35, "S");
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  doc.text(
    "ภาค/SEMESTER_________________________ปีการศึกษา/ACADEMIC YEAR___________________วิทยาเขต/CAMPUS_______________________________________",
    10,
    36
  );

  thaitext(doc, valueTransform(props.Data?.form_semester), 42, 36);

  thaitext(doc, valueTransform(props.Data?.form_semesterYear), 112, 36);

  thaitext(doc, "บางเขน", 170, 36);

  doc.text("รหัสประจำตัวนิสิต/ID", 10, 47);
  doc.rect(43, 42, 6, 8, "S");
  doc.rect(49, 42, 6, 8, "S");
  doc.rect(55, 42, 6, 8, "S");
  doc.rect(61, 42, 6, 8, "S");
  doc.rect(67, 42, 6, 8, "S");
  doc.rect(73, 42, 6, 8, "S");
  doc.rect(79, 42, 6, 8, "S");
  doc.rect(85, 42, 6, 8, "S");
  doc.rect(91, 42, 6, 8, "S");
  doc.rect(97, 42, 6, 8, "S");

  thaitext(doc, valueTransform(props.DataUser?.Code[0]), 45, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[1]), 51, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[2]), 57, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[3]), 63, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[4]), 69, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[5]), 75, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[6]), 81, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[7]), 87, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[8]), 93, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[9]), 99, 47);

  thaitext(
    doc,
    "ชื่อนิสิต/STUDENT'S NAME_____________________________________________________________เบอร์โทรศัพท์/PHONE NUMBER______________________________",
    10,
    57
  );

  thaitext(doc, fullNameTransform(props), 55, 57);

  thaitext(doc, valueTransform(props.DataUser?.Phonenumber), 170, 57);

  doc.text(
    "คณะ/FACULTY______________ภาควิชา/DEPARTMENT______________________________________________รหัสสาขาวิชาเอก/MAJOR CODE_______________",
    10,
    65
  );

  thaitext(doc, valueTransform(props.DataUser?.Faculty), 35, 65);
  thaitext(doc, valueTransform(props.DataUser?.Department), 88, 65);
  thaitext(doc, valueTransform(props.DataUser?.MajorCode), 187, 65);

  thaitext(
    doc,
    "ชื่ออาจารย์ที่ปรึกษา/ADVISOR'S NAME____________________________________________รหัสอาจารย์ที่ปรึกษา/ADVISOR'S CODE________________________",
    10,
    74
  );

  thaitext(doc, fullNameTransform(props, "advisor"), 69, 74);

  thaitext(doc, valueTransform(props.DataUser?.ReportTo?.Code), 180, 74);

  doc.rect(5, 5, doc.internal.pageSize.width - 10, 74, "S");

  thaitext(doc, "รายวิชาที่ขอลงทะเบียนเรียน/LIST OF COURSES REGISTER", 5, 86);

  let testSubject = {
    form_credit: 0,
    form_subjectId: "",
    form_categories_lab: "",
    form_categories_lec: "",
    form_typeofRegister: "",
    form_nameofSubjectEn: "",
  };

  const subjectLength = 15 - props.Data?.form_subjects?.length;

  let subjectEmpty = Object.assign([], props.Data?.form_subjects);

  for (let index = 0; index < subjectLength; index++) {
    subjectEmpty.push(testSubject);
  }

  const subjectDetail = subjectEmpty.map((x, i) => {
    const result = [
      {
        content: i + 1,
      },
      {
        content: x.form_subjectId[0] ?? "  ",
      },
      {
        content: x.form_subjectId[1] ?? "  ",
      },
      {
        content: x.form_subjectId[2] ?? "  ",
      },
      {
        content: x.form_subjectId[3] ?? "  ",
      },
      {
        content: x.form_subjectId[4] ?? "  ",
      },
      {
        content: x.form_subjectId[5] ?? "  ",
      },
      {
        content: x.form_subjectId[6] ?? "  ",
      },
      {
        content: x.form_subjectId[7] ?? "  ",
      },
      {
        content: x.form_typeofRegister ?? "  ",
      },
      {
        content: x.form_categories_lec ?? "  ",
      },
      {
        content: x.form_categories_lab ?? "  ",
      },
      {
        content: x.form_nameofSubjectEn ?? "  ",
      },
      {
        content: x.form_credit === 0 ? " " : x.form_credit,
      },
    ];
    return result;
  });

  console.log("subjectDetail", subjectDetail);

  doc.autoTable({
    margin: { left: 5, top: 90, right: 5, bottom: 0 },
    styles: { font: "Sarabun-Regular", fontSize: 8 },
    didDrawCell: function (data) {
      if (data.column.index === 14 && data.cell.section === "body") {
        var textPos = data.cell;
        if (data.row.raw[12]) {
          const step = props.ApprovalStep.filter(
            (x) => x.ForDev && x.ForDev.SubjectName === data.row.raw[12].content
          )[0];
          doc.addImage(
            valueTransform(step?.SignatureImage),
            "JPEG",
            textPos.x + 2,
            textPos.y + 1,
            valueTransform(step?.SignatureDimension?.width),
            5,
            `surenagix-${data.column.index}-${data.row.index}`,
            "NONE",
            0
          );
        }
      }
    },
    headerStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      1: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      2: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      3: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      4: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      5: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      6: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      7: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      8: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      9: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      10: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      11: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      12: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      13: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      14: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
    },
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },

    head: [
      [
        {
          content: "ลำดับ\nNO.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "รหัสวิชา\nCOURSE CODE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
          colSpan: 8,
        },
        {
          content: "ประเภทการ\nลงทะเบียน\nTYPE OF\nREGIS.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "หมู่เรียน/SECTION",
          styles: { halign: "center", valign: "middle" },
          colSpan: 2,
        },
        {
          content: "ชื่อวิชา(เขียนเป็นภาษาอังกฤษ)/COURSE TITLE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "หน่วยกิต\nCREDITS",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "ลงนามอาจารย์\nประจำวิชา\nINSTRUCTURE's\nSIGNATURE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
      ],
      [
        {
          content: "บรรยาย\nLECTURE",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ปฏิบัติการ\nLAB",
          styles: { halign: "center", valign: "middle" },
        },
      ],
    ],
    body: subjectDetail,
  });
  doc.setFontSize(8);
  thaitext(doc, "่", 111.2, 99);
  doc.setFontSize(10);
  doc.text(
    "ลงนามนิสิต/STUDENT'S SIGNATURE_________________________________________ลงนามอาจารย์/ADVISOR'S SIGNATURE____________________________________",
    5,
    280
  );

  doc.addImage(
    valueTransform(props.ApprovalStep[0]?.SignatureImage),
    "JPEG",
    70,
    275,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature",
    "NONE",
    0
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureImage
    ),
    "JPEG",
    175,
    275,
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-teacher",
    "NONE",
    0
  );

  thaitext(
    doc,
    "วันที่/DATE_________________________________________",
    43,
    290
  );
  thaitext(doc, "วันที่/DATE____________________________________", 147, 290);
  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.DatetimeUpdated
    ),
    165,
    289
  );

  thaitext(
    doc,
    dateSignature(
      props.ApprovalStep[0]?.DatetimeUpdated
        ? props.ApprovalStep[0]?.DatetimeUpdated
        : props.ApprovalStep[0]?.DatetimeCreated
    ),
    67,
    289
  );

  doc.output("dataurlnewwindow");
  // doc.save("form1.pdf");
};
