export const thaitext = (doc, str, x, y) => {
  var sara = ["่", "้", "๊", "๋", "์"];
  var pushers = ["ิ", "ี", "ึ", "ื", "ำ", "ั"];
  var base = "";
  var dim = doc.getTextDimensions(str);
  for (var i = 0; i < str.length; i++) {
    var c = str.charAt(i);
    if (sara.indexOf(c) < 0) {
      base += c;
    } else {
      var pusher = base.charAt(base.length - 1);
      if (pushers.indexOf(pusher) < 0) {
        if (str.charAt(i + 1) != "" && str.charAt(i + 1) == "ำ") {
          var len = doc.getTextWidth(base + "ำ");
          doc.text(c, x + len, y - dim.h / 4);
        } else {
          base += c;
        }
      } else {
        var len = doc.getTextWidth(base);
        doc.text(c, x + len, y - dim.h / 4);
      }
    }
  }
  doc.text(base, x, y);
};

export const thaitextReason = (doc, str, x, y) => {
  var sara = ["่", "้", "๊", "๋", "์"];
  var pushers = ["ิ", "ี", "ึ", "ื", "ำ", "ั"];
  var base = "";
  var dim = doc.getTextDimensions(str);
  for (var i = 0; i < str.length; i++) {
    var c = str.charAt(i);
    if (sara.indexOf(c) < 0) {
      base += c;
    } else {
      var pusher = base.charAt(base.length - 1);
      if (pushers.indexOf(pusher) < 0) {
        if (str.charAt(i + 1) != "" && str.charAt(i + 1) == "ำ") {
          var len = doc.getTextWidth(base + "ำ");
          doc.text(c, x + len, y - dim.h / 4);
        } else {
          base += c;
        }
      } else {
        var len = doc.getTextWidth(base);
        doc.text(c, x + len, y - dim.h / 4);
      }
    }
  }
  doc.text(base, x, y, { lineHeightFactor: 2, maxWidth: 190 });
};

export const thaitextReasonMove = (doc, str, x, y) => {
  var sara = ["่", "้", "๊", "๋", "์"];
  var pushers = ["ิ", "ี", "ึ", "ื", "ำ", "ั"];
  var base = "";
  var dim = doc.getTextDimensions(str);
  for (var i = 0; i < str.length; i++) {
    var c = str.charAt(i);
    if (sara.indexOf(c) < 0) {
      base += c;
    } else {
      var pusher = base.charAt(base.length - 1);
      if (pushers.indexOf(pusher) < 0) {
        if (str.charAt(i + 1) != "" && str.charAt(i + 1) == "ำ") {
          var len = doc.getTextWidth(base + "ำ");
          doc.text(c, x + len, y - dim.h / 4);
        } else {
          base += c;
        }
      } else {
        var len = doc.getTextWidth(base);
        doc.text(c, x + len, y - dim.h / 4);
      }
    }
  }
  doc.text(base, x, y, { lineHeightFactor: 2, maxWidth: 170 });
};
export const thaitextCenter = (doc, str, x, y) => {
  var sara = ["่", "้", "๊", "๋", "์"];
  var pushers = ["ิ", "ี", "ึ", "ื", "ำ", "ั"];
  var base = "";
  var dim = doc.getTextDimensions(str);
  for (var i = 0; i < str.length; i++) {
    var c = str.charAt(i);
    if (sara.indexOf(c) < 0) {
      base += c;
    } else {
      var pusher = base.charAt(base.length - 1);
      if (pushers.indexOf(pusher) < 0) {
        if (str.charAt(i + 1) != "" && str.charAt(i + 1) == "ำ") {
          var len = doc.getTextWidth(base + "ำ");
          doc.text(c, x + len, y - dim.h / 4);
        } else {
          base += c;
        }
      } else {
        var len = doc.getTextWidth(base);
        doc.text(c, x + len, y - dim.h / 4);
      }
    }
  }
  doc.text(base, x, y, "center");
};
