import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";

const initialState = {
  isLoading: false,
  isCompleted: null,
  error: null,
};

export const fetchDraftFormUpload = createAsyncThunk(
  "requestupload/draft",
  async (dataForm, { rejectWithValue }) => {
    try {

      const isLt5M = dataForm.file.size / 1024 / 1024 < 5;

      if (!isLt5M) {
        console.log("reject filesize");
        rejectWithValue("filesize");
        return "filesize";
      }

      if (dataForm.filename.length > 50) {
        console.log("reject filelength");
        rejectWithValue("filelength");
        return "filelength";
      }
      let formData = new FormData();
      formData.append("file", dataForm.file);
      formData.append("filename", dataForm.filename);
      const response = await ApiClient.post(
        `/formTransactions/${dataForm.id}/file/local/`,
        formData
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const draftForm = createSlice({
  name: "draftFormUpload",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDraftFormUpload.pending]: (state) => {
      state.isLoading = true;
      state.isCompleted = null;
    },
    [fetchDraftFormUpload.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isCompleted = action.payload;
    },
    [fetchDraftFormUpload.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default draftForm.reducer;
