import "./style.scss";

import { Button, Col, Form, Input, Row, Select, Space, Upload } from "antd";

import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";

import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";

const { TextArea } = Input;
export default function RequesForm7(form) {
  const { Option } = Select;

  const sigCanvas = useRef({});
  const [filename, setFilename] = useState(null);
  const [isClearSignature, setIsClearSignature] = React.useState(false);
  const adviserMasterData = useSelector(
    (state) => state.masterData.masterData.professors
  );
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="wrap-form wrap-form-1">
      <Row gutter={20}>
        <Col md={16} xs={24}>
          <Form.Item
            name="form_reason"
            label="สาเหตุที่ลา"
            rules={[
              {
                required: true,
                message: "กรุณาระบุ สาเหตุที่ลา",
              },
              {
                max: 100,
                message: "ตัวอักษรไม่ควรเกิน 100 ตัวอักษร",
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={100}
              style={{ height: 180 }}
              placeholder="สาเหตุที่ลา"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={24} xs={24}>
          <Row gutter={8}>
            <Col md={4} sm={24}>
              <Form.Item
                name="form_purpose"
                label="พักการเรียนจำนวน"
                className="addon-after-border-radios"
              >
                <Input addonAfter={"ภาคการศึกษา"} readOnly={true} />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <Form.Item
                name="form_purpose_1"
                label="ภาคการศึกษา"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ ภาคการศึกษา",
                  },
                ]}
              >
                <Select>
                  <Option key={1} value="ภาคต้น">
                    ภาคต้น{" "}
                  </Option>
                  <Option key={2} value="ภาคปลาย">
                    ภาคปลาย
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <Form.Item
                name="form_purpose_2"
                label="ปีการศึกษา"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ ปีการศึกษา",
                  },
                  {
                    max: 4,
                    message: "ปีการศึกษาไม่ควรเกิน 4 ตัวอักษร",
                  },
                ]}
              >
                <Input placeholder="ปีการศึกษา" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <p className="form-7-text-explain">
        อนึ่ง ข้าพเจ้าได้ลงทะเบียนเรียนไว้ดังนี้
      </p>

      <Row gutter={20}>
        <Col md={24}>
          <div className="wrap-dynamic-responsive">
            <div style={{ width: 512 }}>
              <Form.List name="form_subjects_leave">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "form_subjectId"]}
                          label={key === 0 ? "รหัสวิชา" : null}
                          rules={[
                            {
                              required: true,
                              message: "กรุณาระบุ รหัสวิชา",
                            },
                            {
                              max: 8,
                              message: "รหัสวิชาไม่เกิน 8 ตัวอักษร",
                            },
                          ]}
                        >
                          <Input
                            style={{ width: 180 }}
                            placeholder="รหัสวิชา"
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "form_typeOfTeacher"]}
                          label={key === 0 ? "สังกัดอาจารย์ผู้สอน" : null}
                          rules={[
                            {
                              required: true,
                              message: "กรุณาระบุ สังกัดอาจารย์ผู้สอน",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: 160 }}
                            placeholder="เลือก"
                            optionFilterProp="children"
                            onChange={() => {
                              forceUpdate();
                            }}
                          >
                            <Option value="internal">อาจารย์สังกัดคณะเกษตร</Option>
                            <Option value="external">อาจารย์ภายนอกคณะเกษตร</Option>
                          </Select>
                        </Form.Item>
                        {form.form.getFieldValue("form_subjects_leave")[key] &&
                          form.form.getFieldValue("form_subjects_leave")[key]
                            ?.form_typeOfTeacher === "internal" ? (
                          <Form.Item
                            {...restField}
                            name={[name, "form_advisorName"]}
                            label={key === 0 ? "ชื่ออาจารย์ประจำวิชา" : null}
                          
                          >
                            <Select
                              style={{ width: 230 }}
                              placeholder="เลือก"
                              optionFilterProp="children"
                            >
                              {adviserMasterData
                                ? adviserMasterData.map((x, i) => (
                                  <Option key={i} value={`${x.Email},${x.Name}`}>
                                    {x.Name}
                                  </Option>
                                ))
                                : null}
                            </Select>
                          </Form.Item>
                        ) : form.form.getFieldValue("form_subjects_leave")[
                          key
                        ] ? (
                          <Form.Item
                            {...restField}
                            name={[name, "form_lectureName"]}
                            label={key === 0 ? "ชื่ออาจารย์" : null}
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุ ชื่ออาจารย์",
                              },
                            ]}
                          >
                            <Input
                              placeholder="ชื่ออาจารย์"
                              style={{ width: 230 }}
                            />
                          </Form.Item>
                        ) : (
                          ""
                        )}
                        <div className="remove-element">
                          {key !== 0 ? (
                            <CloseCircleOutlined
                              onClick={() => {
                                remove(name);
                              }}
                            />
                          ) : null}
                        </div>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        className="add-element"
                        onClick={() => add()}
                        block
                        icon={<PlusCircleOutlined />}
                      >
                        กดเพื่อเพิ่มแถวใหม่
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          </div>
        </Col>
      </Row> */}
      {/* <Row>
        <Col md={20}>
          <div className="wrap-from-7-upload-section">
            <p>
              ในกรณีที่ไม่ได้ลงทะเบียนเรียนในภาคการศึกษาที่ลาพัก
              จะต้องแนบหลักฐานการชำระค่าธรรมเนียมการศึกษาสำหรับภาคการศึกษาที่จะลาพักด้วย
            </p>
            <p>เอกสารแนบ</p>
            <div className="wrap-upload-component">
              <div className="file-name-section">{filename}</div>
              <div className="upload-btn">
                <Form.Item name="form_atth_doc">
                  <Upload
                    accept="image/png, image/jpeg, .pdf"
                    onChange={(e) => {
                      setFilename(e.file.name);
                    }}
                    maxCount={1}
                    customRequest={dummyRequest}
                  >
                    <Button icon={<CloudUploadOutlined />} />
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <span>นามสกุลไฟล์ที่รองรับ pdf. png. jpeg.</span>
          </div>
        </Col>
      </Row> */}
      <Row gutter={20}>
        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาระบุ กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
