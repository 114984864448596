import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../style.scss"

export default function HTTP403Page() {
    const navigate = useNavigate()
    return (
        <div className="content-http-status-page">
            <p className="txt-status">403</p>
            <p className="status-description">You're not permitted to see this.</p>
            <Button className="btn-back-login" onClick={() => { navigate(`/main`) }}>Back Main</Button>
        </div>
    )
}