import "./style.scss";

import { Button, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";

import ApiClient from "../../shared/api/ApiClient";
import ICONUPLOADPROFILE from "../../assets/image/logo/logo-user-green.svg";
import { LeftOutlined } from "@ant-design/icons";
import { USER } from "../../shared/api/ApiEndPoint";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";
import { parseJwt } from "../../util/TokenUtill";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LandingPage from "../Landing/LandingPage";

const STUDENT_ROLE = "STUDENT";
export default function ProfilePage() {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();
  const [masterData, setMasterData] = useState();
  const state = useSelector((state) => state.masterData);
  useEffect(() => {
    if (state?.masterData) {
      setMasterData(state?.masterData);
    }
  }, [state]);

  useEffect(() => {
    getUserProfile();
  }, []);

  async function getUserProfile() {
    try {
      let userDetail = parseJwt(sessionStorage.getItem("access_token"));
      let result = await ApiClient.get(
        `${process.env.REACT_APP_API_HOST}${USER}/${userDetail.user_id}`
      );
      console.log("result", result.data);
      setUserDetail(result.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="wrap-profile-page">
      <div className="wrap-profile-page-header">
        <Button icon={<LeftOutlined />} onClick={() => navigate("/main")} />
        <div>
          <p className="top-txt-content">ข้อมูลส่วนบุคคล</p>
          <div className="wrap-border-bottom-yellow"></div>
        </div>
      </div>
      {userDetail ? (
        <div className="wrap-profile-page-content">
          <Row
            gutter={[30, 16]}
            className="wrap-profile-page-content-section-header"
          >
            <Col md={14} sm={12} className="wrap-profile-page-header-img">
              <Row align="middle" gutter={[30, 16]}>
                <Col>
                  <img
                    src={
                      userDetail.ProfileImage
                        ? userDetail.ProfileImage
                        : ICONUPLOADPROFILE
                    }
                    alt="icon-profile"
                    width={75}
                    height={75}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  ></img>
                </Col>
                <Col>
                  <p>{`${
                    userDetail.ThaiPrename ? userDetail.ThaiPrename + " " : ""
                  }${userDetail?.FirstnameTh} ${userDetail?.LastnameTh}`}</p>
                  <p>{`${userDetail?.FirstnameEng} ${userDetail?.LastnameEng}`}</p>
                </Col>
              </Row>
            </Col>
            <Col
              md={10}
              sm={12}
              className="wrap-profile-page-content-section-header-btn-group"
            >
              <Row justify="end">
                <Button onClick={() => navigate("/edit-profile")}>
                  แก้ไขข้อมูล
                </Button>
                <Button onClick={() => navigate("/main")}>ไปเมนูหลัก</Button>
              </Row>
            </Col>
          </Row>
          <div className="wrap-content-profile-info">
            <div className="wrap-box-profile-page">
              <Row gutter={[30, 16]} style={{ marginTop: 15 }}>
                <Col md={12} xs={24}>
                  {userDetail?.Role === "STUDENT" ||
                  userDetail?.Role === "PROFESSOR" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">
                          {userDetail?.Role === "PROFESSOR"
                            ? "เลขประจำตัวอาจารย์"
                            : userDetail?.Role === "OFFICER"
                            ? "เลขประจำตัวเจ้าหน้าที่"
                            : "เลขประจำตัวนิสิต"}
                        </p>
                      </Col>
                      <Col md={14} xs={12}>
                        <p>{`${userDetail?.Code ?? ""}`}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row gutter={[30, 16]}>
                    <Col md={10} xs={12}>
                      <p className="wrap-txt-section">คณะ</p>
                    </Col>
                    <Col md={14} xs={12}>
                      <p>{`${userDetail?.Faculty}`}</p>
                    </Col>
                  </Row>
                  {(userDetail?.Role === "STUDENT" ||
                    userDetail?.Role === "PROFESSOR") &&
                  userDetail?.Department === "วิทยาศาสตร์เกษตร" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">แขนงวิชา</p>
                      </Col>
                      <Col md={14} xs={12}>
                        <p>{`${userDetail?.Major}`}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {userDetail?.Role === "STUDENT" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">นิสิตชั้นปีที่</p>
                      </Col>
                      <Col md={14} xs={12}>
                        <p>{userDetail?.AcademicYear}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row gutter={[30, 16]}>
                    <Col md={10} xs={12}>
                      <p className="wrap-txt-section">EMAIL</p>
                    </Col>
                    <Col md={14} xs={12}>
                      <p>{userDetail?.PersonalEmail}</p>
                    </Col>
                  </Row>
                  <Row gutter={[30, 16]}>
                    <Col md={10} xs={12}>
                      <p className="wrap-txt-section">โทรศัพท์</p>
                    </Col>
                    <Col md={14} xs={12}>
                      <p>{userDetail?.Phonenumber}</p>
                    </Col>
                  </Row>
                </Col>

                {/* ========== */}
                <Col md={12} xs={24}>
                  {userDetail?.Role === "STUDENT" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">อาจารย์ที่ปรึกษา</p>
                      </Col>
                      <Col md={14} xs={12}>
                        <p>
                          {
                            masterData?.professors.find(
                              (x) => x.Id === userDetail?.ReportToId
                            )?.Name
                          }
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {userDetail?.Role === "STUDENT" ||
                  userDetail?.Role === "PROFESSOR" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">สาขาวิชา</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.Department}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {userDetail?.Role === "STUDENT" &&
                  userDetail?.Department === "วิทยาศาสตร์เกษตร" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">รหัสสาขาวิชา</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.MajorCode}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {userDetail?.Role === "STUDENT" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">สถานะการศึกษา</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.Status}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {userDetail?.Role === "STUDENT" ? (
                    <Row gutter={[30, 16]}>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">วัน/เดือน/ปีเกิด</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail.DateOfBirth
                          ? dayjs(userDetail.DateOfBirth)
                              .locale(localeTh)
                              .format("DD MMM BBBB")
                          : ""}
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {/* <Row gutter={[30, 16]}>
                                    <Col md={10} xs={12}>
                                        <p className='wrap-txt-section'>ที่อยู่ตามทะเบียนบ้าน</p>
                                    </Col>
                                    <Col md={14} xs={12}>{userDetail?.HomeAddress}</Col>
                                </Row> */}
                </Col>
              </Row>
            </div>

            {/* =============== */}
            {userDetail.Role === "STUDENT" ? (
              <Row gutter={[30, 10]} style={{ marginTop: 15 }}>
                <Col md={12} sm={24} xs={24}>
                  <div className="wrap-profile-page-header">
                    <span>ที่อยู่ปัจจุบัน</span>
                    <div className="border-bottom-custom"></div>
                  </div>
                  <div className="wrap-box-profile-page">
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">บ้านเลขที่</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.HomeNo}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">หมู่ที่</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.VillageNo}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">หมู่บ้าน</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.VillageName}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">ซอย</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.Soi}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">ถนน</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.Road}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">แขวง/ตำบล</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.District}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">เขต/อำเภอ</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.City}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">จังหวัด</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.Province}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={10} xs={12}>
                        <p className="wrap-txt-section">รหัสไปรษณีย์</p>
                      </Col>
                      <Col md={14} xs={12}>
                        {userDetail?.CurrentAddress.Zipcode}
                      </Col>
                    </Row>
                  </div>
                </Col>

                {userDetail.Role === "STUDENT" ? (
                  <Col md={12} sm={24} xs={24}>
                    <div className="wrap-profile-page-header">
                      <span>ผู้ติดต่อกรณีฉุกเฉิน</span>
                      <div className="border-bottom-custom"></div>
                    </div>
                    <div className="wrap-box-profile-page">
                      <Row>
                        <Col md={10} xs={12}>
                          <p className="wrap-txt-section">ชื่อ - ชื่อสกุล</p>
                        </Col>
                        <Col md={14} xs={12}>
                          {userDetail?.FamilyContact}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={10} xs={12}>
                          <p className="wrap-txt-section">โทรศัพท์</p>
                        </Col>
                        <Col md={14} xs={12}>
                          {userDetail?.FamilyPhone}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={10} xs={12}>
                          <p className="wrap-txt-section">ความสัมพันธ์</p>
                        </Col>
                        <Col md={14} xs={12}>
                          {userDetail?.FamilyRelation}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            ) : (
              <></>
            )}
            {userDetail.Role === "STUDENT" ? (
              <Row gutter={[30, 10]} style={{ marginTop: 15 }}>
                <Col md={12}>
                  <div className="wrap-profile-page-header">
                    <span>โรคประจำตัว</span>
                    <div className="border-bottom-custom"></div>
                  </div>
                  <div className="wrap-box-profile-page">
                    <Row>{`${userDetail?.MedicalInformation ?? ""}`}</Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <Spin size="default" />
      )}
    </div>
  );
}
