import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";

dayjs.extend(customParseFormat).extend(buddhistEra);
export const dateSignature = (date) => {
  if (date) {
    return `  ${dayjs(date).locale(localeTh).format("DD")}        ${dayjs(date)
      .locale(localeTh)
      .format("MMM")}     ${dayjs(date).locale(localeTh).format("BBBB")}`;
  } else {
    return "";
  }
};

// thaitext(doc, "........./.........../............", 65, 197);
