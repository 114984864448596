import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const persistConfig = {
  key: "state",
  storage: storage,
  blacklist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, routerMiddleware(history)],
});

export default () => {
  let store = configStore;
  let persistor = persistStore(configStore);
  return { store, persistor };
};
