




import "./style.scss";

import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef } from "react";

import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

export default function RequesForm2(form) {
  const topicsKu3Reason = useSelector(
    (state) => state.masterData.masterData.ku3Reason
  );
  const sigCanvas = useRef({});
  const [isClearSignature, setIsClearSignature] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const adviserMasterData = useSelector(
    (state) => state.masterData.masterData.professors
  );
  useEffect(() => {
    if (form.form.signatureImage) {
      form.setFieldsValue({
        signature: form.signatureImage,
      });
    }
  }, []);
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  const calSumCredit = () => {
    const creditSum = form.form
      .getFieldValue("form_en_subjects")
      .map((x) => {
        const array = [
          x.form_credit_lecture ? x.form_credit_lecture : "0",
          x.form_credit_lab ? x.form_credit_lab : "0",
        ];
        return array;
      })
      .flat(Infinity)
      .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    if (creditSum) {
      form.form.setFieldsValue({
        form_en_sumCredit: creditSum,
      });
    }
    console.log("creditSum EN", creditSum);
    return creditSum;
  };

  const calSumCreditWd = () => {
    const creditSum = form.form
      .getFieldValue("form_wd_subjects")
      .map((x) => {
        const array = [
          x.form_wd_credit_lecture ? x.form_wd_credit_lecture : "0",
          x.form_wd_credit_lab ? x.form_wd_credit_lab : "0",
        ];
        return array;
      })
      .flat(Infinity)
      .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    if (creditSum) {
      form.form.setFieldsValue({
        form_wd_sumCredit: creditSum,
      });
    }
    console.log("creditSum WD", creditSum);
    return creditSum;
  };

  return (
    <div className="wrap-form wrap-form-1">
      {/* <Row gutter={20}>
        <Col md={12}>
          <Form.Item
            name="form_topic"
            label="เรื่อง"
            rules={[
              {
                max: 250,
                message: "ตัวอักษรไม่ควรเกิน 250 ตัวอักษร",
              },
            ]}
          >
            <Select placeholder="เรื่อง">
              {topicsKu3Reason?.map((x) => (
                <Option value={x.Name}>{x.Name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row> */}
      <h2 className="header-text-style">ส่วนที่ 1 ขอเพิ่มรายวิชา</h2>
      <div className="wrap-dynamic-responsive form-ku-3">
        <div style={{ width: 1200 }}>
          <Form.List name="form_en_subjects">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "form_subjectId"]}
                      label={key === 0 ? "รหัสวิชา" : null}
                      rules={[
                        {
                          min: 8,
                          message: "รหัสวิชา 8 ตัวอักษร",
                        },
                        {
                          max: 8,
                          message: "รหัสวิชาไม่เกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input style={{ width: 100 }} placeholder="รหัสวิชา" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_typeofRegister"]}
                      label={key === 0 ? "ประเภทการลงทะเบียนเรียน" : null}
                    >
                      <Select
                        style={{ width: 200 }}
                        placeholder="เลือก"
                        optionFilterProp="children"
                      >
                        <Option value="Credit">Credit</Option>
                        <Option value="Audit">Audit</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_categories_lec"]}
                      label={key === 0 ? "หมู่เรียนบรรยาย" : null}
                      rules={[
                        {
                          max: 8,
                          message: "หมู่เรียนไม่ควรเกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 105 }}
                        placeholder="หมู่เรียนบรรยาย"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_categories_lab"]}
                      label={key === 0 ? "หมู่เรียนปฏิบัติการ" : null}
                      rules={[
                        {
                          max: 8,
                          message: "หมู่เรียนไม่ควรเกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 120 }}
                        placeholder="หมู่เรียนปฏิบัติการ"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_nameofSubjectEn"]}
                      label={key === 0 ? "ชื่อวิชา (ภาษาอังกฤษ)" : null}
                    >
                      <Input
                        style={{ width: 200 }}
                        placeholder="ชื่อวิชา (ภาษาอังกฤษ)"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_credit_lecture"]}
                      label={key === 0 ? "หน่วยกิตบรรยาย" : null}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]*$"),
                          message: "กรุณาใส่ตัวเลขเท่านั้น",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 140 }}
                        // onBlur={() => {
                        //   calSumCredit();
                        //   form.form.setFieldsValue({
                        //     form_summary_credit:
                        //       calSumCredit() - calSumCreditWd(),
                        //   });
                        // }}
                        maxLength={2}
                        placeholder="หน่วยกิตบรรยาย"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_credit_lab"]}
                      label={key === 0 ? "หน่วยกิตปฏิบัติการ" : null}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]*$"),
                          message: "กรุณาใส่ตัวเลขเท่านั้น",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 140 }}
                        // onBlur={() => {
                        //   calSumCredit();
                        //   form.form.setFieldsValue({
                        //     form_summary_credit:
                        //       calSumCredit() - calSumCreditWd(),
                        //   });
                        // }}
                        maxLength={2}
                        placeholder="หน่วยกิตปฏิบัติการ"
                      />
                    </Form.Item>
                    {/* <Form.Item
                      {...restField}
                      name={[name, "form_typeOfTeacher"]}
                      label={key === 0 ? "สังกัดอาจารย์ผู้สอน" : null}
                      rules={[
                      ]}
                    >
                      <Select
                        style={{ width: 160 }}
                        placeholder="เลือก"
                        optionFilterProp="children"
                        onChange={() => {
                          forceUpdate();
                        }}
                      >
                        <Option value="internal">อาจารย์สังกัดคณะเกษตร</Option>
                        <Option value="external">อาจารย์ภายนอกคณะเกษตร</Option>
                      </Select>
                    </Form.Item>
                    {form.form.getFieldValue("form_en_subjects")[key] &&
                    form.form.getFieldValue("form_en_subjects")[key]
                      ?.form_typeOfTeacher === "internal" ? (
                      <Form.Item
                        {...restField}
                        name={[name, "form_advisorName"]}
                        label={key === 0 ? "ชื่ออาจารย์ประจำวิชา" : null}

                      >
                        <Select
                          style={{ width: 230 }}
                          placeholder="เลือก"
                          optionFilterProp="children"
                        >
                          {adviserMasterData
                            ? adviserMasterData.map((x, i) => (
                                <Option key={i} value={x.Email}>
                                  {x.Name}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    ) : form.form.getFieldValue("form_en_subjects")[key] ? (
                      <Form.Item
                        {...restField}
                        name={[name, "form_lectureName"]}
                        label={key === 0 ? "ชื่ออาจารย์" : null}
                      >
                        <Input
                          placeholder="ชื่ออาจารย์"
                          style={{ width: 230 }}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )} */}
                    <div className="remove-element">
                      {key !== 0 ? (
                        <CloseCircleOutlined
                          onClick={() => {
                            remove(name);
                            calSumCredit();
                          }}
                        />
                      ) : null}
                    </div>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className="add-element"
                    onClick={() => add()}
                    block
                    icon={<PlusCircleOutlined />}
                  >
                    กดเพื่อเพิ่มแถวใหม่
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </div>

      <hr />
      <h2 className="header-text-style" style={{ marginTop: 24 }}>
        ส่วนที่ 2 ของดรายวิชา
      </h2>
      <div className="wrap-dynamic-responsive form-ku-3">
        <div style={{ width: 1200 }}>
          <Form.List name="form_wd_subjects">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "form_wd_subjectId"]}
                      label={key === 0 ? "รหัสวิชา" : null}
                      rules={[
                        {
                          min: 8,
                          message: "รหัสวิชา 8 ตัวอักษร",
                        },
                        {
                          max: 8,
                          message: "รหัสวิชาไม่เกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input style={{ width: 100 }} placeholder="รหัสวิชา" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_wd_typeofRegister"]}
                      label={key === 0 ? "ประเภทการลงทะเบียนเรียน" : null}
                      rules={[
                        {
                          message: "กรุณาระบุ ประเภทการลงทะเบียนเรียน",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 200 }}
                        placeholder="เลือก"
                        optionFilterProp="children"
                      >
                        <Option value="Credit">Credit</Option>
                        <Option value="Audit">Audit</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_categories_lec"]}
                      label={key === 0 ? "หมู่เรียนบรรยาย" : null}
                      rules={[
                        {
                          max: 8,
                          message: "หมู่เรียนไม่ควรเกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 105 }}
                        placeholder="หมู่เรียนบรรยาย"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_categories_lab"]}
                      label={key === 0 ? "หมู่เรียนปฏิบัติการ" : null}
                      rules={[
                        {
                          max: 8,
                          message: "หมู่เรียนไม่ควรเกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 120 }}
                        placeholder="หมู่เรียนปฏิบัติการ"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_wd_nameofSubjectEn"]}
                      label={key === 0 ? "ชื่อวิชา (ภาษาอังกฤษ)" : null}
                    >
                      <Input
                        style={{ width: 200 }}
                        placeholder="ชื่อวิชา (ภาษาอังกฤษ)"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_wd_credit_lecture"]}
                      label={key === 0 ? "หน่วยกิตบรรยาย" : null}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]*$"),
                          message: "กรุณาใส่ตัวเลขเท่านั้น",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 140 }}
                        // onBlur={() => {
                        //   calSumCreditWd();
                        //   form.form.setFieldsValue({
                        //     form_summary_credit:
                        //       calSumCredit() - calSumCreditWd(),
                        //   });
                        // }}
                        maxLength={2}
                        placeholder="หน่วยกิตบรรยาย"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_wd_credit_lab"]}
                      label={key === 0 ? "หน่วยกิตปฏิบัติการ" : null}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9]*$"),
                          message: "กรุณาใส่ตัวเลขเท่านั้น",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 140 }}
                        // onBlur={() => {
                        //   calSumCreditWd();
                        //   form.form.setFieldsValue({
                        //     form_summary_credit:
                        //       calSumCredit() - calSumCreditWd(),
                        //   });
                        // }}
                        maxLength={2}
                        placeholder="หน่วยกิตปฏิบัติการ"
                      />
                    </Form.Item>
                    {/* <Form.Item
                      {...restField}
                      name={[name, "form_typeOfTeacher"]}
                      label={key === 0 ? "สังกัดอาจารย์ผู้สอน" : null}
                    >
                      <Select
                        style={{ width: 160 }}
                        placeholder="เลือก"
                        optionFilterProp="children"
                        onChange={() => {
                          forceUpdate();
                        }}
                      >
                        <Option value="internal">อาจารย์สังกัดคณะเกษตร</Option>
                        <Option value="external">อาจารย์ภายนอกคณะเกษตร</Option>
                      </Select>
                    </Form.Item>
                    {form.form.getFieldValue("form_wd_subjects")[key] &&
                    form.form.getFieldValue("form_wd_subjects")[key]
                      ?.form_typeOfTeacher === "internal" ? (
                      <Form.Item
                        {...restField}
                        name={[name, "form_advisorName"]}
                        label={key === 0 ? "ชื่ออาจารย์ประจำวิชา" : null}
                      >
                        <Select
                          style={{ width: 230 }}
                          placeholder="เลือก"
                          optionFilterProp="children"
                        >
                          {adviserMasterData
                            ? adviserMasterData.map((x, i) => (
                                <Option key={i} value={x.Email}>
                                  {x.Name}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    ) : form.form.getFieldValue("form_wd_subjects")[key] ? (
                      <Form.Item
                        {...restField}
                        name={[name, "form_lectureName"]}
                        label={key === 0 ? "ชื่ออาจารย์" : null}
                      >
                        <Input
                          placeholder="ชื่ออาจารย์"
                          style={{ width: 230 }}
                        />
                      </Form.Item>
                    ) : (
                      ""
                    )} */}
                    <div className="remove-element">
                      {key !== 0 ? (
                        <CloseCircleOutlined
                          onClick={() => {
                            remove(name);
                            calSumCreditWd();
                          }}
                        />
                      ) : null}
                    </div>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className="add-element"
                    onClick={() => add()}
                    block
                    icon={<PlusCircleOutlined />}
                  >
                    กดเพื่อเพิ่มแถวใหม่
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </div>
      <hr />
      <Form.Item
        name="form_summary_credit"
        className="form2-input-after"
        label="สรุปการลงทะเบียนเรียนหลังการเพิ่ม-ถอน"
        rules={[
          {
            required: true,
            message: "กรุณาระบุ หน่วยกิตรวม",
          },
        ]}
      >
        <Input addonAfter={"หน่วยกิต"} style={{ width: 150 }} />
      </Form.Item>
      <Row>
        <Form.Item
          name="form_semester"
          label="ภาคเรียน"
          style={{ paddingRight: "8px" }}
        >
          <Select
            style={{ width: 200 }}
            placeholder="เลือก"
            optionFilterProp="children"
          >
            <Option value="ภาคต้น">ภาคต้น</Option>
            <Option value="ภาคฤดูร้อน">ภาคฤดูร้อน</Option>
            <Option value="ภาคปลาย">ภาคปลาย</Option>
          </Select>
        </Form.Item>
        <Form.Item name="form_semesterYear" label="ปีการศึกษา">
          <Select
            style={{ width: 200 }}
            placeholder="เลือก"
            optionFilterProp="children"
          >
            <Option value="2566">2566</Option>
            <Option value="2567">2567</Option>
            <Option value="2568">2568</Option>
            <Option value="2569">2569</Option>
            <Option value="2570">2570</Option>
          </Select>
        </Form.Item>
      </Row>

      <Row gutter={20}>
        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
