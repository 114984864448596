import "jspdf-autotable";

import { fullNameTransform, valueTransform } from "../../util/ValueTransform";
import { thaitext, thaitextCenter, thaitextReason } from "../../util/Thaitext";

import { dateSignature } from "../../util/DateSignature";
import jsPDF from "jspdf";
import kuLogo from "../../assets/image/logo/ku_logo.jpeg";
import qrCode from "../../assets/image/qrcode/form-example.jpg";
import sarabunFont from "../../assets/fonts/sarabun/THSarabunNew.ttf";
import sarabunFontMedium from "../../assets/fonts/sarabun/Sarabun-Medium.ttf";
import sarabunLight from "../../assets/fonts/sarabun/Sarabun-Light.ttf";
import sarabunRegular from "../../assets/fonts/sarabun/Sarabun-Regular.ttf";
import { thaiDate } from "../../util/DateObject";

export const Form3 = async (props) => {
  const doc = new jsPDF({
    orientation: "p",
    format: "a4",
  });

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.addFont(sarabunLight, "Sarabun-Light", "normal");
  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.addImage(
    kuLogo,
    "JPEG",
    doc.internal.pageSize.getWidth() / 2 - 12,
    5,
    25,
    25,
    0,
    "NONE",
    0
  );
  doc.text(
    "มหาวิทยาลัยเกษตรศาสตร์",
    doc.internal.pageSize.getWidth() / 2 - 25,
    40
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(12);
  thaitext(doc, "คำร้องทั่วไป", doc.internal.pageSize.getWidth() / 2 - 10, 47);

  //Righr QRcode
  doc.addImage(
    qrCode,
    "JPEG",
    doc.internal.pageSize.getWidth() / 2 + 70,
    5,
    30,
    30,
    "qrCode",
    "NONE",
    0
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ตัวอย่างการเขียนคำร้อง",
    doc.internal.pageSize.getWidth() / 2 + 70,
    40
  );
  //End Right QRcode

  // Contect
  doc.setFontSize(10);
  thaitext(
    doc,
    `วันที่.............เดือน....................................พ.ศ................`,
    doc.internal.pageSize.getWidth() / 2 - 35,
    54
  );
  thaitext(
    doc,
    thaiDate(props.DatetimeCreated).day,
    doc.internal.pageSize.getWidth() / 2 - 25,
    53
  );
  thaitext(
    doc,
    thaiDate(props.DatetimeCreated).month,
    doc.internal.pageSize.getWidth() / 2,
    53
  );
  thaitext(doc, thaiDate(props.DatetimeCreated).year, 137, 53);
  thaitext(
    doc,
    `เรื่อง.................................................................`,
    10,
    65
  );
  thaitext(
    doc,
    valueTransform(props.Data.form_topic) === "อื่นๆ"
      ? valueTransform(props.Data.form_topic_etc)
      : valueTransform(props.Data.form_topic),
    20,
    64
  );
  thaitext(
    doc,
    "เรียน.................................................................",
    10,
    73
  );
  thaitext(
    doc,
    valueTransform(
      `${props.DataUser.ReportTo.ThaiPrename} ${props.DataUser.ReportTo.FirstnameTh} ${props.DataUser.ReportTo.LastnameTh}`
    ),
    20,
    72
  );
  doc.setFontSize(9);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "(อาจารย์ที่ปรึกษา)", 25, 78);

  doc.setFontSize(10);
  doc.setFont("Sarabun-Regular");
  thaitext(
    doc,
    "ข้าพเจ้า.................................................................................................................เลขประจำตัว.................................. นิสิตปีที่ .............",
    20,
    85
  );
  thaitext(
    doc,
    ` ${valueTransform(props?.DataUser?.ThaiPrename)} ${valueTransform(
      props?.DataUser?.FirstnameTh
    )}   ${valueTransform(props?.DataUser?.LastnameTh)}`,
    35,
    84
  );

  thaitext(doc, ` ${valueTransform(props.DataUser.Code)}`, 155, 84);

  thaitext(doc, ` ${valueTransform(props.DataUser.AcademicYear)}`, 195, 84);

  thaitext(
    doc,
    "สาขาวิชาเอก.................................................................................................คณะ..............................................................................................",
    10,
    92
  );

  thaitext(
    doc,
    valueTransform(
      props.DataUser.Major && props.DataUser.Major !== ""
        ? props.DataUser.Major
        : props.DataUser.Department
    ),
    35,
    91
  );
  thaitext(doc, valueTransform(props.DataUser.Faculty), 125, 91);

  thaitext(
    doc,
    "ที่อยู่ปัจจุบัน........................................................................................................................................................................................................",
    10,
    99
  );

  thaitext(
    doc,
    valueTransform(props.DataUser.CurrentAddress.HomeNo) +
      " " +
      valueTransform(props.DataUser.CurrentAddress.Soi) +
      " " +
      valueTransform(props.DataUser.CurrentAddress.VillageNo) +
      " " +
      valueTransform(props.DataUser.CurrentAddress.City) +
      " " +
      valueTransform(props.DataUser.CurrentAddress.Province) +
      " " +
      valueTransform(props.DataUser.CurrentAddress.Country) +
      " " +
      valueTransform(props.DataUser.CurrentAddress.Zipcode),
    35,
    98
  );

  thaitext(
    doc,
    "โทรศัพท์........................................................................................E-mail..........................................................................................................",
    10,
    106
  );

  thaitext(doc, valueTransform(props.DataUser.Phonenumber), 30, 105);
  thaitext(doc, valueTransform(props.DataUser.Email), 120, 105);

  thaitext(doc, "ความประสงค์ (ระบุความประสงค์และเหตุผล)", 10, 113);
  thaitextReason(doc, valueTransform(props.Data.form_reason), 15, 119);

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................",
    10,
    120
  );

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................",
    10,
    127
  );

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................",
    10,
    134
  );

  thaitext(doc, "จึงเรียนมาเพื่อโปรดพิจารณา", 20, 141);
  thaitext(
    doc,
    "ลงนาม....................................................",
    140,
    148
  );
  thaitext(
    doc,
    "(                                                       )",
    150,
    155
  );

  doc.addImage(
    valueTransform(props.ApprovalStep[0]?.SignatureImage),
    "JPEG",
    165,
    142,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature",
    "NONE",
    0
  );

  thaitextCenter(
    doc,
    valueTransform(props?.DataUser?.ThaiPrename) +
      " " +
      valueTransform(props.DataUser.FirstnameTh) +
      " " +
      valueTransform(props.DataUser.LastnameTh),
    175,
    155
  );
  doc.setFont("Sarabun-Light");
  doc.rect(10, 161, 95.25, 43);
  doc.rect(105.25, 161, 95.25, 43);
  doc.rect(10, 204, 95.25, 50);
  doc.rect(105.25, 204, 95.25, 50);

  // Start box 1
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "1. เรียน หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ", 15, 166);
  thaitext(
    doc,
    "........................................................................................................",
    20,
    176
  );
  thaitext(doc, valueTransform(props.ApprovalStep[1]?.Comment), 25, 175);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    187
  );
  doc.addImage(
    valueTransform(props.ApprovalStep[1]?.SignatureImage),
    "JPEG",
    71,
    181,
    valueTransform(props.ApprovalStep[1]?.SignatureDimension?.width),
    5,
    "signature-step-1",
    "NONE",
    0
  );
  thaitext(doc, "(.................................................)", 55, 192);

  thaitext(doc, fullNameTransform(props, "advisor"), 57, 191);
  thaitext(doc, "........./.........../............", 65, 197);

  thaitext(doc, "อาจารย์ที่ปรึกษา", 68, 202);
  thaitext(
    doc,
    dateSignature(
      props.ApprovalStep[1]?.DatetimeUpdated ??
        props.ApprovalStep[1]?.DatetimeCreated
    ),
    65,
    196
  );
  // End Box 1

  // Start Box 2
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "2. เรียน คณบดี", 15, 209);
  thaitext(
    doc,
    "........................................................................................................",
    20,
    219
  );
  thaitext(doc, valueTransform(props.ApprovalStep[2]?.Comment), 25, 218);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    230
  );
  doc.addImage(
    valueTransform(props.ApprovalStep[2]?.SignatureImage),
    "JPEG",
    70,
    225,
    valueTransform(props.ApprovalStep[2]?.SignatureDimension?.width),
    5,
    "signature-step-2",
    "NONE",
    0
  );
  thaitext(doc, "(.................................................)", 55, 235);
  thaitext(
    doc,
    valueTransform(
      props.ApprovalStep[2]?.ForDev?.Major?.Chief ??
        props.ApprovalStep[2]?.ForDev?.Department?.Chief
    ),
    57,
    234
  );
  thaitext(doc, "........./.........../............", 65, 240);

  thaitext(doc, dateSignature(props.ApprovalStep[2]?.DatetimeUpdated), 65, 239);
  thaitext(doc, "หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ", 52, 245);
  // End Box 2

  // Start Box 3
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "3. เรียน คณบดี", 110, 166);
  thaitext(
    doc,
    "........................................................................................................",
    115,
    176
  );
  thaitext(doc, valueTransform(props.ApprovalStep[3]?.Comment), 120, 175);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    187
  );
  doc.addImage(
    valueTransform(props.ApprovalStep[3]?.SignatureImage),
    "JPEG",
    155,
    181,
    valueTransform(props.ApprovalStep[3]?.SignatureDimension?.width),
    5,
    "signature-step-3",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    192
  );
  thaitext(
    doc,
    valueTransform(props.ApprovalStep[3]?.ForDev?.ApprovedBy),
    152,
    191
  );
  thaitext(doc, "........./.........../............", 158, 197);
  thaitext(
    doc,
    dateSignature(props.ApprovalStep[3]?.DatetimeUpdated),
    158,
    196
  );
  thaitext(doc, "เจ้าหน้าที่ฝ่ายการศึกษา", 155, 202);
  // End Box 3

  // Start Box 4
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    `4. ${valueTransform(props.ApprovalStep[5]?.ForDev?.AgendaForDean)}`,
    110,
    209
  );
  thaitext(
    doc,
    "........................................................................................................",
    115,
    219
  );
  thaitext(doc, valueTransform(props.ApprovalStep[5]?.Comment), 120, 218);
  doc.setFont("Sarabun-Light");

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    230
  );
  doc.addImage(
    valueTransform(props.ApprovalStep[5]?.SignatureImage),
    "JPEG",
    168,
    225,
    valueTransform(props.ApprovalStep[5]?.SignatureDimension?.width),
    5,
    "signature-step-4",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    235
  );
  thaitext(
    doc,
    valueTransform(props.ApprovalStep[5]?.ApprovalTemplate.FullName),
    152,
    234
  );
  thaitext(doc, "........./.........../............", 160, 240);
  thaitext(
    doc,
    dateSignature(props.ApprovalStep[5]?.DatetimeUpdated),
    160,
    239
  );
  thaitext(doc, "รองคณบดีฝ่ายวิชาการ", 158, 245);
  thaitext(doc, "ปฏิบัติหน้าที่แทนคณบดีคณะเกษตร", 150, 251);
  doc.output("dataurlnewwindow");
  // doc.save("form1.pdf");
};
