import "./style.scss";

import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ApiClient from "../../shared/api/ApiClient";
import { Form1 } from "../RequestFormExport/FormKU1";
import { Form2 } from "../RequestFormExport/FormKU3";
import { Form3 } from "../RequestFormExport/FormGeneral";
import { Form4 } from "../RequestFormExport/FormMove";
import { Form5 } from "../RequestFormExport/FormOff";
import { Form6 } from "../RequestFormExport/FormLeave";
import { Form7 } from "../RequestFormExport/FormBreak";
import { LeftOutlined } from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import { getImageDimensions } from "../../util/GetImageDimensions";
import { useForm } from "antd/lib/form/Form";
import { useSelector } from "react-redux";
import { max } from "moment";

const { Content } = Layout;

const { TextArea } = Input;

const { Option } = Select;

export default function TeacherApprove() {
  const navigate = useNavigate();
  const [radioActive, setRadioActive] = useState(1);
  const [signature, setSignature] = useState(null);
  const [form] = useForm();
  const sigCanvas = useRef({});
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [comment, setComment] = useState("");
  const location = useLocation();
  const [topic, setTopic] = useState(undefined);
  const [advisorName, setAdvisorName] = useState(undefined);
  const [advisorCode, setAdvisorCode] = useState(undefined);
  const [loadingViewPdf, setloadingViewPdf] = useState(false);
  const prefix = useSelector((state) => state.masterData.masterData.admin);
  const prefixDean = useSelector(
    (state) => state.masterData.masterData.deanTitle
  );
  const [prefixState, setPrefixState] = useState(null);
  const [prefixDeanState, setPrefixDeanState] = useState(null);

  useEffect(() => {
    console.log("location", location);
  }, [location]);

  const clear = () => {
    sigCanvas.current.clear();
    setSignature(undefined);
  };
  const save = () => {
    setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  async function onSubmitFromApproveTeacher(event) {
   
    try {
      if (!signature) {
        alert(`กรุณาลงนามกำกับเอกสาร`);
        return;
      }
      if (!comment && location.state.ApprovalTemplate.Name !== "คณบดี") {
        alert(`กรุณาแสดงข้อคิดเห็น/ข้อเสนอแนะ`);
        return;
      }

      if (location.state.ApprovalTemplate.Name === "ประธานบริหารหลักสูตรใหม่") {
        if (!advisorName) {
          alert(`กรุณาระบุอาจารย์ที่ปรึกษา`);
          return;
        }

        if (!advisorCode) {
          alert(`กรุณาระบุรหัสอาจารย์ที่ปรึกษา`);
          return;
        }
      }
      const signatureDimension = await getImageDimensions(signature);
      const imgWidth = signatureDimension.width;
      const imgHeight = signatureDimension.height;
      const calWidth = imgWidth / (imgHeight / 5);
      const calHeight = 5;
      let ApprovedBy =
        location.state.ApprovalTemplate.Code === "studyOfficer"
          ? prefixState
            ? prefixState
            : prefix[0].Name
          : undefined;
      let AgendaForDean =
        location.state.ApprovalTemplate.Code === "officeAdmin"
          ? prefixDeanState
            ? prefixDeanState
            : prefixDean[0].Name
          : undefined;
      let objSave = {
        SignatureImage: signature,
        Comment: comment,
        FormTransactionId: location.state.FormTransactionId,
        Action: radioActive === 1 ? `APPROVED` : `REJECTED`,
        ApprovalTemplateId: location.state.ApprovalTemplateId,
        SignatureDimension: {
          width: Number(calWidth.toFixed(2)),
          height: calHeight,
        },

        Topic: topic,
        ForDev: {
          advisorName,
          advisorCode,
          ApprovedBy,
          AgendaForDean,
          topic,
          ...location.state.ForDev,
        },
      };
      console.log("objSave", objSave);
      try {
        await ApiClient.patch(
          `${process.env.REACT_APP_API_HOST}/approvalSteps/${location.state.Id}`,
          objSave
        );
        notification.success({
          message: `Success`,
          description: `Success !`,
        });
        if (location.state.ApprovalTemplate.Name === "ผู้ปกครอง") {
          navigate(`/list-document-student`);
        } else {
          navigate(`/list-document-teacher`);
        }
      } catch (error) {
        console.log("error", error);
        notification.error({
          message: `Error`,
          description: `Something went wrong !`,
        });
      }
    } finally {
      setLoadingApprove(false);
    }
  }

  async function getTransectionDetail(id) {
    if (id) {
      setloadingViewPdf(true);
      try {
        let result = await (
          await ApiClient.get(
            `${process.env.REACT_APP_API_HOST}/formTransactions/${id}/detail`
          )
        ).data;
        setloadingViewPdf(false);
        return result;
      } catch (error) {
        setloadingViewPdf(false);
        console.log("error", error);
        notification.error({
          message: `Error`,
          description: `Something went wrong !`,
        });
      }
    }
  }

  return (
    <Content className="site-layout-background">
      <div className="navigate-top">
        <div className="left">
          <Button
            onClick={() => {
              if (location.state.ApprovalTemplate.Name === "ผู้ปกครอง") {
                navigate(`/list-document-student`);
              } else {
                navigate(`/list-document-teacher`);
              }
            }}
            icon={<LeftOutlined />}
          />
          <h1 className="top-txt-content">ยื่นคำร้อง</h1>
        </div>
        <div className="right">
          <Button onClick={() => navigate("/main")}>ไปเมนูหลัก</Button>
        </div>
      </div>
      <br></br>
      {location.state ? (
        <div className="wrap-compoent-approve-teacher">
          <div className="wrap-top-detail-request">
            <Row gutter={[16, 16]}>
              <Col md={3}>
                <p className="txt-label">นิสิตผู้ยื่นคำร้อง</p>
              </Col>
              <Col md={21}>
                <p>
                  {location.state.FormTransaction.DataUser.FirstnameTh
                    ? (location.state.FormTransaction.DataUser.ThaiPrename ??
                        "") +
                      ` ` +
                      location.state.FormTransaction.DataUser.FirstnameTh +
                      ` ` +
                      location.state.FormTransaction.DataUser.LastnameTh
                    : "-"}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={3}>
                <p className="txt-label">ชื่อคำร้อง</p>
              </Col>
              <Col md={21}>
                {(() => {
                  if (location?.state?.FormTransaction.FormTypeId === 4) {
                    return (
                      <p className="wrap-txt-detail">
                        {location?.state?.FormTransaction?.Data?.form_topic ===
                        1
                          ? "ขอย้ายสาขาวิชา"
                          : "ขอย้ายแขนงวิชา"}
                      </p>
                    );
                  } else if (
                    location?.state?.FormTransaction.FormTypeId === 5
                  ) {
                    return (
                      <p className="wrap-txt-detail">
                        {location?.state?.FormTransaction?.Data?.form_topic ===
                        1
                          ? "ลาป่วย"
                          : "ลากิจ"}
                      </p>
                    );
                  } else if (location?.FormTransaction?.Data?.form_topic) {
                    return (
                      <p className="wrap-txt-detail">
                        {location?.state?.FormTransaction?.Data?.form_topic}
                      </p>
                    );
                  } else {
                    return (
                      <p className="wrap-txt-detail">
                        {location.state.ApprovalTemplate.FormType.Name}
                      </p>
                    );
                  }

                  return null;
                })()}
              </Col>
            </Row>
            <Row>
              <Button
                className="btn-view-doc"
                loading={loadingViewPdf}
                onClick={async () => {
                  let transectionDetail = await getTransectionDetail(
                    location.state.FormTransactionId
                  );
                  if (transectionDetail.FormType.Code === "KU1") {
                    Form1(transectionDetail);
                  } else if (transectionDetail.FormType.Code === "KU3") {
                    Form2(transectionDetail);
                  } else if (transectionDetail.FormType.Code === "GEN") {
                    Form3(transectionDetail);
                  } else if (transectionDetail.FormType.Code === "MOVE") {
                    Form4(transectionDetail);
                  } else if (transectionDetail.FormType.Code === "OFF") {
                    Form5(transectionDetail);
                  } else if (transectionDetail.FormType.Code === "LEAVE") {
                    Form6(transectionDetail);
                  } else if (transectionDetail.FormType.Code === "BREAK") {
                    Form7(transectionDetail);
                  } else {
                    return;
                  }
                }}
              >
                ดูเอกสารคำร้อง
              </Button>
            </Row>
          </div>
          <br />
          <div className="wrap-border-green-approve-teacher"></div>
          <br />
          <div className="wrap-approv-teacher">
            <p>การอนุมัติคำร้องของท่าน</p>
            <Radio.Group
              onChange={(value) => {
                setRadioActive(value.target.value);
              }}
              value={radioActive}
            >
              <Radio value={1}>อนุมัติ</Radio>
              <Radio value={2}>ไม่อนุมัติ</Radio>
            </Radio.Group>
            <br />
            <br />
          </div>
          {location.state.ApprovalTemplate.Code === "studyOfficer" ? (
            <div className="wrap-approv-teacher">
              <p>ผู้อนุมัติ</p>
              <Select
                style={{ width: 300 }}
                defaultValue={[prefix[0].Name]}
                onChange={(e) => {
                  setPrefixState(e);
                }}
              >
                {prefix?.map((x, i) => (
                  <Option key={i} value={x.Name}>
                    {x.Name}
                  </Option>
                ))}
              </Select>
              <br />
              <br />
            </div>
          ) : (
            <></>
          )}
          {location.state.ApprovalTemplate.Code === "officeAdmin" ? (
            <>
              <div className="wrap-approv-teacher">
                <p>เรียน</p>
                <Select
                  style={{ width: 360 }}
                  defaultValue={[prefixDean[0].Name]}
                  onChange={(e) => {
                    setPrefixDeanState(e);
                  }}
                >
                  {prefixDean?.map((x, i) => (
                    <Option key={i} value={x.Name}>
                      {x.Name}
                    </Option>
                  ))}
                </Select>
                <br />
                <br />
              </div>
            </>
          ) : (
            ""
          )}

          {location.state.ApprovalTemplate.Name === "ผู้ปกครอง" ? (
            <Row gutter={[30, 16]}>
              <Col md={10} className="wrap-please-approv">
                <p>ชื่อผู้ปกครอง</p>
                <Input
                  onChange={(x) => {
                    setTopic(x.target.value);
                  }}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}
          {location.state.ApprovalTemplate.Name ===
          "ประธานบริหารหลักสูตรใหม่" ? (
            <Row gutter={[30, 16]}>
              <Col md={10} className="wrap-please-approv">
                <p>
                  {" "}
                  <span style={{ color: "red" }}>* </span> ชื่ออาจารย์ที่ปรึกษา
                </p>
                <Input
                  onChange={(x) => {
                    setAdvisorName(x.target.value);
                  }}
                />
              </Col>
              <Col md={4} className="wrap-please-approv">
                <p>
                  {" "}
                  <span style={{ color: "red" }}>* </span> รหัสอาจารย์ที่ปรึกษา
                </p>
                <Input
                  onChange={(x) => {
                    setAdvisorCode(x.target.value);
                  }}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}

          <br />
          <Row gutter={[30, 16]}>
            <Col md={10}>
              <p>
                {" "}
                {location.state.ApprovalTemplate.Name === "คณบดี" ? (
                  ""
                ) : (
                  <span style={{ color: "red" }}>* </span>
                )}
                ข้อคิดเห็น/ข้อเสนอแนะเพิ่มเติม
              </p>
            </Col>
            <Col md={14}>
              <p>
                {" "}
                <span style={{ color: "red" }}>* </span> กรุณาลงนามกำกับเอกสาร
              </p>
            </Col>
          </Row>
          <Row gutter={[30, 16]}>
            <Col md={10}>
              <Form.Item name={"reason"} rules={[]}>
                <TextArea
                  showCount
                  maxLength={45}
                  rows={8}
                  onChange={(x) => setComment(x.target.value)}
                />
              </Form.Item>
            </Col>
            <Col md={14}>
              <div className="wrap-canvas">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: "335px",
                    height: "160px",
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    save();
                  }}
                />
                {form.signatureImage ? (
                  <div className="wrap-image-signature">
                    <img src={form.signatureImage} alt="signature" />
                  </div>
                ) : null}
                <Button onClick={clear}>Clear</Button>
              </div>
            </Col>
          </Row>
          <Row className="btn-approve-teacher">
            <Button
              disabled={loadingApprove}
              loading={loadingApprove}
              onClick={async (event) => {
                setLoadingApprove(true);
                await onSubmitFromApproveTeacher(event);
              }}
            >
              บันทึก
            </Button>
            <Button
              onClick={() => {
                navigate("/list-document-teacher");
              }}
            >
              ยกเลิก
            </Button>
          </Row>
        </div>
      ) : (
        <Spin />
      )}
    </Content>
  );
}
