import  {Button} from "antd";
import React from "react";
import './style.scss'

import LOGOAERICULTURE from '../../assets/image/logo/logologin.jpg'

export default function LoginPage() {
  // const navigate = useNavigate();
  // const [isLoading, setisLoading] = useState(false);
  // useEffect(() => {
  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);
  //   const code = urlParams.get('code')
  //   if (code) {
  //     console.log('code', code);
  //     authenticationService(code)
  //   }
  // }, []);

  // async function authenticationService(code) {
  //   setisLoading(true)
  //   try {
  //     let payload = { "authorization_code": code }
  //     let result = await (await Axios.post(`${process.env.REACT_APP_API_HOST}/auth/login`, payload)).data
  //     console.log('result', result)
  //     if (result.access_token) {
  //       sessionStorage.setItem("access_token", result.access_token)
  //       sessionStorage.setItem("refresh_main", "true")
  //      const checked =  await validateUserProfile(result.access_token);
  //      if(checked){
  //       navigate('/main')
  //      }else {
  //       navigate('/edit-profile')
  //      }

  //     }
  //     setisLoading(false)
  //   } catch (error) {
  //     console.log('error', error)
  //     setisLoading(false)
  //   }
  // }

  // async function validateUserProfile(asscessToken) {
  //   let resultToken = parseJwt(asscessToken)
  //   let keyCheck = ["Department", "Major", "Status", "Phonenumber", "DateOfBirth", "AcademicYear", "ReportTo"]
  //   let currentAddressCheck = ["City", "Country", "HomeNo", "Province", "Soi", "VillageNo", "Zipcode"]
  //   let checked = true;
  //   try {
  //     let result = await Axios.get(`${process.env.REACT_APP_API_HOST}${ApiEndPoint.USER}/${resultToken?.user_id}`, {
  //       headers: {
  //         'Authorization': `Bearer ${asscessToken}`
  //       }
  //     })
  //     if (result) {
  //       let userData = result.data
  //       console.log('userData', userData)
  //       keyCheck.forEach(x => {
  //         if (!userData[x] || userData[x] === "") {
  //           console.log(x, '====> false')
  //           checked = false
  //         }
  //       })
  //       let userCurrnetAddress = result.data.CurrentAddress;
  //       currentAddressCheck.forEach(x => {
  //         if (!userCurrnetAddress[x] || userCurrnetAddress[x] === "") {
  //           console.log(x, '====> false')
  //           checked = false
  //         }
  //       })
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //     checked = true
  //   }

  //   return checked;
  // }


  return (

    <div className="wrap-login-page">
        <div className="wrap-login-content">
          <img style={{ height: '70px',marginBottom: '5px'}} src={LOGOAERICULTURE} alt="logo-agriculture"></img>
          <div className="wrap-label-login-page">
            <p>
              ระบบสารสนเทศสำหรับนิสิต
            </p>
            <p>
              คณะเกษตร มหาวิทยาลัยเกษตรศาสตร์
            </p>
          </div>

          <div className="login-box-yellow"></div>

          <div className="wrap-login-form">
            <a href="https://sso.ku.ac.th/nidp/oauth/nam/authz?response_type=code&redirect_uri=https://service.agr.ku.ac.th&client_id=ce5d13be-2beb-4403-a8a0-093e463505e5&scope=basic&state=" rel="noreferrer"> <Button className="btn-login">Login</Button></a>
          </div>
        </div>
    </div>
  )
}
