import "./style.scss";

import {
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import localeTh from "dayjs/locale/th";
import ApiClient from "../../shared/api/ApiClient";
import ICONDELETE from "../../assets/image/logo/icon-delete.svg";
import ICONEDIT from "../../assets/image/logo/icon-edit.svg";
import ICONVIEW from "../../assets/image/logo/view-icon.svg";
import { LeftOutlined } from "@ant-design/icons";
import ModalViewStatusReportDocument from "./Modal/ModalViewStatusReportDocument";
import dayjs from "dayjs";
import { parseJwt } from "../../util/TokenUtill";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ListDocumentStudent() {
  const [visibleModalViewStatus, setVisibleModalViewStatus] = useState(false);
  const navigate = useNavigate();
  const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [transectionSelected, setTransectionSelected] = useState();
  const state = useSelector((state) => state.masterData);
  const [formTransectionaction, setFormTransectionaction] = useState();
  const [paginationSize, setPaginationSize] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStatusReportForm, setSearchStatusReportForm] = useState(null);

  useEffect(() => {
    if (state?.masterData) {
      setFormTransectionaction(state.masterData.formTransactionAction);
    } else {
      navigate("/main");
    }
  }, [state]);

  function getSignDateTime(transectionDetail) {
    let item = {};
    for (
      let index = transectionDetail?.ApprovalStep.length - 1;
      index > -1;
      index--
    ) {
      const element = transectionDetail?.ApprovalStep[index];
      if (
        element.Action === "APPROVED" ||
        element.ApprovalTemplate.StepNumber === 0
      ) {
        item = element;
        break;
      }
    }
    return item.DatetimeUpdated
      ? dayjs(new Date(item.DatetimeUpdated))
          .locale(localeTh)
          .format("DD MMM BBBB เวลา HH:mm")
      : dayjs(new Date(item.DatetimeCreated))
          .locale(localeTh)
          .format("DD MMM BBBB เวลา HH:mm");
  }
  const columns = [
    {
      title: "ชื่อคำร้อง",
      dataIndex: "documentName",
      key: "documentName",
      width: "20%",
      render: (text, record, index) => {
        return record?.FormType.Name;
      },
    },
    {
      title: "วันที่ยื่นคำร้อง",
      dataIndex: "createDate",
      key: "createDate",
      width: "17%",
      render: (text, record, index) => {
        return record?.DatetimeCreated
          ? dayjs(new Date(record?.DatetimeCreated))
              .locale(localeTh)
              .format("DD MMM BBBB เวลา HH:mm")
          : "-";
      },
    },
    {
      title: "ดูสถานะปัจจุบัน",
      dataIndex: "currentStatus",
      key: "currentStatus",
      width: "15%",
      render: (text, record, index) => {
        if (
          record?.FormType.Code === "KU1" ||
          record?.FormType.Code === "KU3"
        ) {
          return "ดาวน์โหลด และยื่นใบคำร้องในแบบฟอร์มทั่วไป";
        }

        if (record?.Action === "DRAFT") {
          return "นิสิต";
        }
        let rejected = record.ApprovalStep.filter(
          (x) => x.Action === "REJECTED"
        );
        if (rejected.length > 0) {
          return (
            <p key={index} style={{ color: "red" }}>
              {" "}
              ไม่อนุมัติ <br /> กรณีเอกสารไม่ครบถ้วนโปรดดำเนินการใหม่ <br />{" "}
              กรณีอื่นติดต่อเจ้าหน้าที่{" "}
            </p>
          );
        }

        let pendingStep = record.ApprovalStep.filter(
          (x) => x.Action === "PENDING"
        );
        if (
          pendingStep.length === 0 &&
          record.ApprovalStep[record.ApprovalStep.length - 1]
        ) {
          pendingStep.push(record.ApprovalStep[record.ApprovalStep.length - 1]);
          if (record.FormType.Code === "MOVE") {
            pendingStep[0].ApprovalTemplate.Name =
              "อยู่ระหว่างการพิจารณาของคณะกรรมการประจำคณะ";
          } else if (record.FormType.Code === "LEAVE") {
            pendingStep[0].ApprovalTemplate.Name =
              "ดาวน์โหลดเอกสารและยื่นแบบฟอร์มตามขั้นตอนต่อไป";
          } else {
            pendingStep[0].ApprovalTemplate.Name = "สำเร็จ";
          }
          if (
            pendingStep[0].ApprovalTemplate.Name ===
            "อยู่ระหว่างการพิจารณาของคณะกรรมการประจำคณะ"
          ) {
            return (
              <p key={index} style={{ color: "#f5b041" }}>
                {" "}
                อยู่ระหว่างการพิจารณาของคณะกรรมการประจำคณะ{" "}
              </p>
            );
          }
          if (
            pendingStep[0].ApprovalTemplate.Name ===
            "ดาวน์โหลดเอกสารและยื่นแบบฟอร์มตามขั้นตอนต่อไป"
          ) {
            return (
              <p key={index} style={{ color: "#f5b041" }}>
                {" "}
                ดาวน์โหลดเอกสารและยื่นแบบฟอร์มตามขั้นตอนต่อไป{" "}
              </p>
            );
          }
        }
        return pendingStep &&
          pendingStep[0] &&
          pendingStep[0].ApprovalTemplate ? (
          pendingStep[0]?.ApprovalTemplate?.Name === "แอดมิน" ? (
            "ผู้ดูแลระบบ"
          ) : pendingStep[0]?.ApprovalTemplate?.Name === "สำเร็จ" ? (
            <p key={index} className="form-success-message">
              {" "}
              ลงนามครบถ้วน นิสิตโปรดนำส่งหน่วยงานที่เกี่ยวข้อง
            </p>
          ) : (
            pendingStep[0]?.ApprovalTemplate?.Name
          )
        ) : (
          `-`
        );
      },
    },
    {
      title: "สถานะล่าสุด",
      dataIndex: "statusUpdate",
      key: "statusUpdate",
      width: "17%",
      render: (text, record, index) => {
        return getSignDateTime(record);
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "currentStatus",
      key: "currentStatus",
      width: "10%",
      render: (text, record, index) => {
        let rejected = record.ApprovalStep.filter(
          (x) => x.Action === "REJECTED"
        );
        if (rejected.length > 0) {
          return rejected[0].Notation;
        }
        return "-";
      },
    },
    {
      title: "",
      dataIndex: "address",
      key: "address",
      width: "20%",
      render: (text, record, index) => {
        let data = record.ApprovalStep[record.ApprovalStep.length - 1];
        let FormTransaction = {
          DataUser: {
            FirstnameTh: record.DataUser.FirstnameTh,
            LastnameTh: record.DataUser.LastnameTh,
          },
        };

        let ApprovalTemplate = {
          FormType: { Name: record.FormType.Name },
          Name: data?.ApprovalTemplate?.Name,
        };
        let step = { ...data, FormTransaction, ApprovalTemplate };
        return (
          <div
            key={"group-btn" + index}
            className="wrap-group-button-list-document-student"
          >
            <img
              src={ICONVIEW}
              alt="icon-view"
              onClick={() => {
                setVisibleModalViewStatus(true);
                setTransectionSelected(record.Id);
              }}
            ></img>

            {record?.Action !== "DRAFT" ? (
              record.ApprovalStep[record.ApprovalStep.length - 1]
                ?.ApprovalTemplate?.Name == "ผู้ปกครอง" ? (
                <img
                  src={ICONEDIT}
                  onClick={() => {
                    navigate(`/approve`, { state: step });
                  }}
                  alt="icon-edit"
                ></img>
              ) : (
                <img
                  src={ICONEDIT}
                  className="btn-disble"
                  alt="icon-edit"
                ></img>
              )
            ) : (
              <img
                src={ICONEDIT}
                onClick={() => {
                  navigate(`/request-form?Id=${record.Id}`);
                }}
                alt="icon-edit"
              ></img>
            )}

            <img
              class={
                record.ApprovalStep[record.ApprovalStep.length - 1]
                  ?.ApprovalTemplate?.StepNumber > 1 ||
                record.ApprovalStep[record.ApprovalStep.length - 1]?.Action ===
                  "REJECTED"
                  ? "disble-delete-btn"
                  : ""
              }
              src={ICONDELETE}
              alt="icon-delete"
              onClick={() => {
                if (
                  record.ApprovalStep[record.ApprovalStep.length - 1]
                    ?.ApprovalTemplate?.StepNumber > 1 ||
                  record.ApprovalStep[record.ApprovalStep.length - 1]
                    ?.Action === "REJECTED"
                ) {
                  return;
                }
                let text =
                  "Press a confirm delete transection !\nEither OK or Cancel.";
                if (window.confirm(text) == true) {
                  console.log("ok");
                  deletFormTransection(record.Id);
                } else {
                  console.log("You canceled!");
                }
              }}
            ></img>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getFormTransectionList(5, 1);
  }, []);

  async function getFormTransectionList(take, page) {
    setIsLoadingDataTable(true);
    try {
      let userDetail = parseJwt(sessionStorage.getItem("access_token"));
      let result = await (
        await ApiClient.get(
          `${process.env.REACT_APP_API_HOST}/users/${
            userDetail.user_id
          }/forms?take=${take}&page=${page}${
            searchStatusReportForm !== null
              ? "&status=" + searchStatusReportForm
              : ""
          }`
        )
      ).data;
      setDataTable(result.Items);
      setPaginationSize(result?.LastPageNumber);
      setIsLoadingDataTable(false);
    } catch (error) {
      setIsLoadingDataTable(false);
    }
  }

  // function sortList(a, b) {
  //     if (a.Id > b.Id) {
  //         return -1;
  //     }
  //     if (a.Id < b.Id) {
  //         return 1;
  //     }
  //     return 0;
  // };

  async function deletFormTransection(id) {
    try {
      setIsLoadingDataTable(true);
      await ApiClient.delete(
        `${process.env.REACT_APP_API_HOST}/formTransactions/${id}`
      );
      await getFormTransectionList(5, currentPage);
    } catch (error) {
      setIsLoadingDataTable(false);
      notification.error({
        description: "Error",
        message: "Something want wrong !",
      });
    }
  }

  return (
    <div className="wrap-list-document-student-page">
      <div className="wrap-list-document-student-page">
        <div className="navigate-top">
          <div className="left">
            <Button icon={<LeftOutlined />} onClick={() => navigate("/main")} />
            <h1 className="top-txt-content">เอกสารคำร้องทั้งหมด</h1>
          </div>
          <div className="right">
            <Button onClick={() => navigate("/main")}>ไปเมนูหลัก</Button>
          </div>
        </div>
        <div className="wrap-search-section-document-student">
          <Form name="form-search-doc" onFinish={(x) => console.log("x", x)}>
            <Row gutter={[16, 16]}>
              <Col md={7}>
                <span className="txt-heder-search-document-student"></span>
                <Form.Item>
                  <Select
                    defaultValue={null}
                    onChange={(x) => setSearchStatusReportForm(x)}
                  >
                    <Select.Option key={"all"} value={null}>
                      ทั้งหมด
                    </Select.Option>
                    {formTransectionaction?.map((x) => {
                      return (
                        <Select.Option key={x.Name} value={`${x.Value}`}>
                          {x.Name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Button
                  className="btn-search-form-doc-student"
                  htmlType="submit"
                  onClick={() => getFormTransectionList(5, 1)}
                >
                  ค้นหา
                </Button>
              </Col>

              {/* <Col md={13}>
                <Row justify="end">
                  <Button
                    className="btn-go-report-page"
                    onClick={() => {
                      navigate("/request-form");
                    }}
                  >
                    ยื่นคำร้องออนไลน์
                  </Button>
                </Row>
              </Col> */}
            </Row>
          </Form>
        </div>
        <div className="wrap-content-table-document-student">
          <Table
            loading={isLoadingDataTable}
            rowKey={(x) => `${x.Id}`}
            key={"table-key"}
            dataSource={dataTable}
            columns={columns}
            pagination={false}
          />
          <br />
          {paginationSize ? (
            <Pagination
              onChange={(x) => {
                getFormTransectionList(5, x);
                setCurrentPage(x);
              }}
              defaultCurrent={1}
              total={paginationSize}
              pageSize={1}
            />
          ) : (
            ""
          )}
        </div>
        <div style={{ marginTop: "30px", fontSize: "24px", color: "red" }}>
          <p>
            ช่องทางการส่งเอกสารคำร้องไปยังสำนักบริหารการศึกษา{" "}
            <a href="https://registrar.ku.ac.th/request" target="_blank">
              https://registrar.ku.ac.th/request
            </a>
          </p>
          {/* <p><a href="https://registrar.ku.ac.th/request" target="_blank">https://registrar.ku.ac.th/request</a></p> */}
        </div>
      </div>
      <ModalViewStatusReportDocument
        transectionId={transectionSelected}
        visible={visibleModalViewStatus}
        closeModal={() => setVisibleModalViewStatus(false)}
      />
    </div>
  );
}
