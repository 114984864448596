import "./style.scss";

import { Button, Col, Form, Input, Row, Select, Spin, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { CloudUploadOutlined } from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

export default function RequesForm3(form) {
  const [isClearSignature, setIsClearSignature] = useState(false);
  const [filename, setFilename] = useState(null);
  const [selectedTopic, setTopic] = useState(null);
  const topicsGeneralReason = useSelector(
    (state) => state.masterData.masterData?.generalReason
  );
  const sigCanvas = useRef({});
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  useEffect(() => {
    const formVal = form.form.getFieldsValue(true);
    setTopic(formVal?.form_topic);
  }, []);

  return (
    <div className="wrap-form wrap-form-1">
      <Row gutter={20}>
        <Col md={24} sm={24} xs={24}>
          <Row gutter={20}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name="form_topic"
                label="เรื่อง"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุเรื่อง",
                  },
                  {
                    max: 250,
                    message: "ตัวอักษรไม่ควรเกิน 250 ตัวอักษร",
                  },
                ]}
              >
                <Select placeholder="เรื่อง" onChange={(x) => setTopic(x)}>
                  {topicsGeneralReason?.map((x) => (
                    <Option value={x.Name}>{x.Name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {selectedTopic === "อื่นๆ" ? (
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  name="form_topic_etc"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุเรื่อง",
                    },
                    {
                      max: 1000,
                      message: "ตัวอักษรไม่ควรเกิน 1000 ตัวอักษร",
                    },
                  ]}
                >
                  <Input placeholder="ระบุเรื่องที่ต้องการยื่นคำร้อง" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Col>

        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name="form_reason"
            label="ความประสงค์ (ระบุความประสงค์และเหตุผล)"
            rules={[
              {
                required: true,
                message: "กรุณาระบุความประสงค์",
              },
              {
                max: 1000,
                message: "ตัวอักษรไม่ควรเกิน 1000 ตัวอักษร",
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={300}
              style={{ height: 180 }}
              placeholder="ความประสงค์ (ระบุความประสงค์และเหตุผล)"
            />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
        {selectedTopic === "ขอลงทะเบียนเรียนข้ามวิทยาเขต" ||
        selectedTopic === "ขอแก้ I (ล่าช้า)" ||
        selectedTopic === "ขอดูคะแนนสอบ" ||
        selectedTopic === "ขอคืนสภาพนิสิต" ? (
          <></>
        ) : (
          <Col md={12} sm={24} xs={24}>
            <div className="wrap-from-7-upload-section upload-form-1">
              {(() => {
                if (selectedTopic === "ขอย้ายคณะ") {
                  return (
                    <>
                      <p>
                        <span class="custom-required">* </span>เอกสารแนบ 1 :
                        Transcript ผลการเรียน
                      </p>
                      <p>
                        <span class="custom-required">* </span>เอกสารแนบ 2 :
                        หนังสือยินยอมจากผู้ปกครอง
                      </p>
                      <p>หมายเหตุ: รวมเอกสารแนบทั้งหมดมาใน pdf.</p>
                    </>
                  );
                } else if (
                  selectedTopic ===
                  "ขอผ่อนผันค่าเทอม (กรณีมีปัญหาทางด้านการเงิน)"
                ) {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      สำเนาบัตรประชาชนผู้ปกครอง <br />
                      พร้อมลงนามยินยอมและรับทราบ
                    </p>
                  );
                } else if (selectedTopic === "ขอลงทะเบียนเรียนข้ามสถาบัน") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      แผนการสอนรายวิชาของสถาบันที่
                      <br />
                      ลงทะเบียน
                    </p>
                  );
                } else if (selectedTopic === "ขอไม่แก้ F (หมวดวิชาเลือกเสรี)") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      Transcript ผลการเรียน
                    </p>
                  );
                } else if (selectedTopic === "ขอไม่โมฆะวิชา") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      Transcript ผลการเรียน
                    </p>
                  );
                } else if (selectedTopic === "ขอใบรับรองสถานภาพนิสิต") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      เอกสารระเบียบการขอรับทุน
                    </p>
                  );
                } else if (selectedTopic === "ขอใบรับรองความประพฤตินิสิต") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      เอกสารระเบียบการขอรับทุน
                    </p>
                  );
                } else if (selectedTopic === "ขอสอบย้อนหลัง") {
                  return (
                    <p>
                      เอกสารแนบ 1 : ใบรับรองแพทย์หรือเอกสารประกอบการสอบย้อนหลัง
                      (ถ้ามี)
                    </p>
                  );
                } else if (selectedTopic === "ขอส่งใบขอจบ (ล่าช้า)") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      ใบขอจบล่าช้า
                    </p>
                  );
                } else if (
                  selectedTopic === "ขอผ่อนผันค่าเทอม (กรณีกู้ยืมเพื่อการศึกษา)"
                ) {
                  return (
                    <p>
                      <span class="custom-required">*</span> เอกสารแนบ :
                      หลักฐานแสดงการกู้ยืม
                    </p>
                  );
                } else if (
                  selectedTopic === "ขอลงทะเบียนเรียน (ล่าช้า)" ||
                  selectedTopic === "ขอลงทะเบียนเรียนต่ำกว่า 9 หน่วยกิต"
                ) {
                  return (
                    <p>
                      <span class="custom-required">*</span> เอกสารแนบ :
                      แบบฟอร์ม KU1
                    </p>
                  );
                } else if (
                  selectedTopic === "ขอเปลี่ยนแปลงการลงทะเบียนเรียน" ||
                  selectedTopic === "ขอลงทะเบียนเรียนทับซ้อน" ||
                  selectedTopic === "ขอลงทะเบียนเรียนควบคู่รายวิชาบังคับก่อน" ||
                  selectedTopic === "ขอเพิ่ม-ถอนรายวิชา (ล่าช้า)" ||
                  selectedTopic === "ขอแก้ไขประเภทการลงทะเบียนเรียน" ||
                  selectedTopic === "ขอลงทะเบียนเรียนเกินหน่วยกิตที่กำหนด"
                ) {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ :
                      แบบฟอร์ม KU3
                    </p>
                  );
                } else if (selectedTopic === "ขอลงทะเบียนเรียนเกิน") {
                  return (
                    <p>
                      <span class="custom-required">* </span>เอกสารแนบ 1 :
                      แบบฟอร์ม KU3
                      <br />
                      <span class="custom-required">* </span>เอกสารแนบ 2 :
                      Transcript ผลการเรียน
                      <br />
                      <span class="custom-required">* </span>เอกสารแนบ 3 :
                      ตารางเรียน
                      <br />
                      หมายเหตุ: รวมเอกสารแนบทั้งหมดมาใน pdf.
                    </p>
                  );
                } else if (selectedTopic === "ขอรักษาสถานภาพนิสิต") {
                  return (
                    <>
                      <p>
                        <span class="custom-required">* </span>เอกสารแนบ 1 :
                        แบบฟอร์ม KU1{" "}
                      </p>
                      <p>
                        <span class="custom-required">* </span>เอกสารแนบ 2 :
                        หลักฐานการชำระเงิน
                      </p>
                      <p> หมายเหตุ: รวมเอกสารแนบทั้งหมดมาใน pdf.</p>
                    </>
                  );
                } else if (selectedTopic === "ขอเทียบโอนรายวิชา") {
                  return (
                    <>
                      <p>
                        <span class="custom-required">* </span>เอกสารแนบ 1 :
                        ตารางการเทียบโอนรายวิชา{" "}
                      </p>
                      <p>
                        <span class="custom-required">* </span>เอกสารแนบ 2 :
                        Transcript ผลการเรียน
                      </p>
                      หมายเหตุ: รวมเอกสารแนบทั้งหมดมาใน pdf.
                    </>
                  );
                } else if (selectedTopic === "ขอแก้ I (ล่าช้า)") {
                  return <p>เอกสารแนบ : (ถ้ามี)</p>;
                } else if (selectedTopic === "อื่นๆ") {
                  return <p>เอกสารแนบ : (ถ้ามี)</p>;
                } else {
                  return <p>เอกสารแนบ : (ถ้ามี)</p>;
                }

                return null;
              })()}

              {/* <div className="wrap-upload-component">
                <div className="file-name-section">
                  {filename
                    ? filename
                    : form.form.getFieldValue("form_atth_doc")}
                </div>
                <div className="upload-btn">
                  <Form.Item
                    name="form_atth_doc"
                    rules={[
                      {
                        required:
                          selectedTopic === "ขอแก้ I (ล่าช้า)" ||
                          selectedTopic === "ขอดูคะแนนสอบ" ||
                          selectedTopic === "ขอคืนสถานภาพนิสิต" ||
                          selectedTopic === "ขอสอบย้อนหลัง" ||
                          selectedTopic === "อื่นๆ"
                            ? false
                            : true,
                        message: "กรุณาแนบไฟล์",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/png, image/jpeg, .pdf"
                      onChange={(e) => {
                        setFilename(e.file.name);
                      }}
                      maxCount={1}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<CloudUploadOutlined />} />
                    </Upload>
                  </Form.Item>
                </div>
              </div> */}

              <div className="custom-upload-field">
                <Form.Item
                  name="form_atth_doc"
                  rules={[
                    {
                      required:
                        selectedTopic === "ขอแก้ I (ล่าช้า)" ||
                        selectedTopic === "ขอดูคะแนนสอบ" ||
                        selectedTopic === "ขอคืนสถานภาพนิสิต" ||
                        selectedTopic === "ขอสอบย้อนหลัง" ||
                        selectedTopic === "อื่นๆ"
                          ? false
                          : true,
                      message: "กรุณาแนบไฟล์",
                    },
                  ]}
                >
                  <Upload
                    accept="image/png, image/jpeg, .pdf"
                    onChange={(e) => {
                      setFilename(e.file.name);
                    }}
                    maxCount={1}
                    customRequest={dummyRequest}
                  >
                    <Button
                      icon={<CloudUploadOutlined />}
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {filename
                        ? filename
                        : form.form.getFieldValue("form_atth_doc")}
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
              <span>นามสกุลไฟล์ที่รองรับ pdf. png. jpeg.</span>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
