import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ApiEndPoint from "../../shared/api/ApiEndPoint";
import ApiClient from "../../shared/api/ApiClient";
import { parseJwt } from "../../util/TokenUtill";

const initialState = {
  isLoading: false,
  userDetail: null,
  error: null,
};

export const fetchUserDetail= createAsyncThunk(
  "getUserDetail",
  async ( path , { rejectWithValue }) => {
    try {
        let token = sessionStorage.getItem('access_token');
        let resultToken = parseJwt(token)
      const response = await ApiClient.get(
        `${process.env.REACT_APP_API_HOST}${ApiEndPoint.USER}/${resultToken?.user_id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getUserDetail = createSlice({
  name: "getUserDetail",
  initialState,
  reducers: {
    clearUserDetail: (state) => {
      state.userDetail = null
    } , 
    clearProfileImg: (state) => {
      if (state.userDetail && state.userDetail.ProfileImage)
        state.userDetail.ProfileImage = undefined;
    }
  },
  extraReducers: {
    [fetchUserDetail.pending]: (state) => {
      state.isLoading = true;
      state.userDetail = null;
    },
    [fetchUserDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userDetail = action.payload;
    },
    [fetchUserDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const {clearUserDetail , clearProfileImg} = getUserDetail.actions;
export default getUserDetail.reducer;
