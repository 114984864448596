import "jspdf-autotable";

import {
  GetApprovalByPositionName,
  GetApprovalByPositionCode,
  fullNameTransform,
  valueTransform,
} from "../../util/ValueTransform";

import { dateSignature } from "../../util/DateSignature";
import jsPDF from "jspdf";
import kuLogo from "../../assets/image/logo/ku_logo.jpeg";
import qrCode from "../../assets/image/qrcode/form-example.jpg";
import sarabunFont from "../../assets/fonts/sarabun/THSarabunNew.ttf";
import sarabunFontMedium from "../../assets/fonts/sarabun/Sarabun-Medium.ttf";
import sarabunLight from "../../assets/fonts/sarabun/Sarabun-Light.ttf";
import sarabunRegular from "../../assets/fonts/sarabun/Sarabun-Regular.ttf";
import { thaiDate } from "../../util/DateObject";
import { thaitext } from "../../util/Thaitext";

export const Form2 = (props) => {
  const doc = new jsPDF({
    orientation: "p",
    format: "a4",
  });

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.rect(5, 5, doc.internal.pageSize.width - 10, 25, "S");
  doc.addImage(kuLogo, "JPEG", 7, 8, 20, 20, 0, "NONE", 0);

  doc.text(
    "มหาวิทยาลัยเกษตรศาสตร์แบบขอเปลี่ยนแปลงการลงทะเบียนเรียน (KU3)",
    doc.internal.pageSize.getWidth() / 2 + 7,
    15,
    { align: "center" }
  );

  doc.text(
    "KASETSART UNIVERSITY REQUEST FOR CHANGE OF REGISTRATION FORM",
    doc.internal.pageSize.getWidth() / 2 + 10,
    23,
    { align: "center" }
  );

  doc.rect(5, 5, doc.internal.pageSize.width - 10, 35, "S");
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  doc.text(
    "ภาค/SEMESTER_________________________ปีการศึกษา/ACADEMIC YEAR___________________วิทยาเขต/CAMPUS_______________________________________",
    10,
    36
  );

  thaitext(doc, valueTransform(props.Data?.form_semester), 42, 36);

  thaitext(
    doc,
    valueTransform(props.Data?.form_semesterYear?.toString()),
    112,
    36
  );
  thaitext(doc, "บางเขน", 170, 36);
  doc.text("รหัสประจำตัวนิสิต/ID", 10, 47);
  doc.rect(43, 42, 6, 8, "S");
  doc.rect(49, 42, 6, 8, "S");
  doc.rect(55, 42, 6, 8, "S");
  doc.rect(61, 42, 6, 8, "S");
  doc.rect(67, 42, 6, 8, "S");
  doc.rect(73, 42, 6, 8, "S");
  doc.rect(79, 42, 6, 8, "S");
  doc.rect(85, 42, 6, 8, "S");
  doc.rect(91, 42, 6, 8, "S");
  doc.rect(97, 42, 6, 8, "S");

  thaitext(doc, valueTransform(props.DataUser?.Code[0]), 45, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[1]), 51, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[2]), 57, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[3]), 63, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[4]), 69, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[5]), 75, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[6]), 81, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[7]), 87, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[8]), 93, 47);
  thaitext(doc, valueTransform(props.DataUser?.Code[9]), 99, 47);

  thaitext(
    doc,
    "ชื่อนิสิต/STUDENT'S NAME_____________________________________________________________เบอร์โทรศัพท์/PHONE NUMBER______________________________",
    10,
    57
  );

  thaitext(doc, fullNameTransform(props), 55, 57);

  thaitext(doc, valueTransform(props.DataUser?.Phonenumber), 170, 57);


  doc.text(
    "คณะ/FACULTY______________ภาควิชา/DEPARTMENT____________________________________________________รหัสสาขาวิชาเอก/MAJOR CODE___________",
    10,
    65
  );

  thaitext(
    doc,
    "ชื่ออาจารย์ที่ปรึกษา/ADVISOR'S NAME____________________________________________รหัสอาจารย์ที่ปรึกษา/ADVISOR'S CODE________________________",
    10,
    74
  );
  thaitext(doc, fullNameTransform(props, "advisor"), 69, 74);


  thaitext(doc, valueTransform(props.DataUser?.ReportTo?.Code), 180, 74);
  thaitext(doc, valueTransform(props.DataUser?.Faculty), 35, 65);
  thaitext(doc, valueTransform(props.DataUser?.Department), 88, 65);
  thaitext(doc, valueTransform(props.DataUser?.MajorCode), 194, 65);

  doc.rect(5, 5, doc.internal.pageSize.width - 10, 74, "S");

  // Form 2 start
  thaitext(doc, "ส่วนที่ 1 ขอเพิ่มรายวิชา/PART I: ADD COURSES", 5, 86);

  // Table section 1

  const testSubjectTop = {
    form_credit: "",
    form_subjectId: "",
    form_advisorName: "",
    form_typeOfTeacher: "",
    form_categories_lab: "",
    form_categories_lec: "",
    form_typeofRegister: "",
    form_nameofSubjectEn: "",
  };

  const subjectLength = 8 - props.Data?.form_en_subjects?.length;

  let subjectEmpty = Object.assign([], props.Data?.form_en_subjects);

  for (let index = 0; index < subjectLength; index++) {
    subjectEmpty.push(testSubjectTop);
  }

  let subjectDetailEnroll = subjectEmpty.map((x, i) => {
    const result = [
      {
        content: i + 1,
      },
      {
        content: x.form_subjectId ? x.form_subjectId[0] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[1] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[2] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[3] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[4] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[5] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[6] ?? "  " : "  ",
      },
      {
        content: x.form_subjectId ? x.form_subjectId[7] ?? "  " : "  ",
      },
      {
        content: valueTransform(x.form_typeofRegister),
      },
      {
        content: valueTransform(x.form_categories_lec),
      },
      {
        content: valueTransform(x.form_categories_lab),
      },
      {
        content: valueTransform(x.form_nameofSubjectEn),
      },
      {
        content: valueTransform(x.form_credit_lecture),
      },
      {
        content: valueTransform(x.form_credit_lab),
      },
    ];
    return result;
  });

  const testSubjectBottom = {
    form_advisorName: " ",
    form_wd_subjectId: " ",
    form_typeOfTeacher: " ",
    form_wd_credit_lab: " ",
    form_categories_lab: " ",
    form_categories_lec: " ",
    form_wd_credit_lecture: " ",
    form_wd_typeofRegister: " ",
    form_wd_nameofSubjectEn: " ",
  };

  const subjectWdLength = 8 - props.Data?.form_wd_subjects?.length;

  let subjectEmptyWd = Object.assign([], props.Data?.form_wd_subjects);

  for (let index = 0; index < subjectWdLength; index++) {
    subjectEmptyWd.push(testSubjectBottom);
  }

  let subjectDetailWithdraw = subjectEmptyWd.map((x, i) => {
    const result = [
      {
        content: i + 1,
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[0] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[1] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[2] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[3] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[4] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[5] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[6] ?? "  " : "  ",
      },
      {
        content: x.form_wd_subjectId ? x.form_wd_subjectId[7] ?? "  " : "  ",
      },
      {
        content: valueTransform(x.form_wd_typeofRegister),
      },
      {
        content: valueTransform(x.form_categories_lec),
      },
      {
        content: valueTransform(x.form_categories_lab),
      },
      {
        content: valueTransform(x.form_wd_nameofSubjectEn),
      },
      {
        content: valueTransform(x.form_wd_credit_lecture),
      },
      {
        content: valueTransform(x.form_wd_credit_lab),
      },
    ];
    return result;
  });

  const sumLabCreditEn =
    props.Data?.form_en_subjects.length > 1
      ? props.Data?.form_en_subjects
          .filter((x) => typeof x.form_credit_lab === "string")
          .reduce((a, b) => {
            return Number(a) + Number(b.form_credit_lab);
          }, 0)
      : props.Data?.form_en_subjects[0].form_credit_lab;

  console.log("sumLabCreditEn", sumLabCreditEn);

  const sumLectureCreditEn =
    props.Data?.form_en_subjects.length > 1
      ? props.Data?.form_en_subjects
          .filter((x) => typeof x.form_credit_lecture === "string")
          .reduce((a, b) => Number(a) + Number(b.form_credit_lecture), 0)
      : props.Data?.form_en_subjects[0].form_credit_lecture;

  console.log("sumLectureCreditEn", sumLectureCreditEn);

  const sumLabCreditWd =
    props.Data?.form_wd_subjects.length > 1
      ? props.Data?.form_wd_subjects
          .filter((x) => typeof x.form_wd_credit_lab === "string")
          .reduce((a, b) => Number(a) + Number(b.form_wd_credit_lab), 0)
      : props.Data?.form_wd_subjects[0].form_wd_credit_lab;

  const sumLectureCreditWd =
    props.Data?.form_wd_subjects.length > 1
      ? props.Data?.form_wd_subjects
          .filter((x) => typeof x.form_wd_credit_lecture === "string")
          .reduce((a, b) => Number(a) + Number(b.form_wd_credit_lecture), 0)
      : props.Data?.form_wd_subjects[0].form_wd_credit_lecture;

  subjectDetailEnroll.push([
    {
      content: "หน่วยกิตรวม/TOTAL CREDIT",
      colSpan: 13,
      styles: {
        halign: "right",
      },
    },
    {
      content: sumLectureCreditEn | "",
      styles: {
        halign: "center",
      },
    },
    {
      content: sumLabCreditEn | "",
      styles: {
        halign: "center",
      },
    },
  ]);

  subjectDetailWithdraw.push([
    {
      content: "หน่วยกิตรวม/TOTAL CREDIT",
      colSpan: 13,
      styles: {
        halign: "right",
      },
    },
    {
      content: sumLectureCreditWd | "",
      styles: {
        halign: "center",
      },
    },
    {
      content: sumLabCreditWd | "",
      styles: {
        halign: "center",
      },
    },
  ]);

  doc.autoTable({
    margin: { left: 5, top: 90, right: 5, bottom: 0 },
    styles: { font: "Sarabun-Regular", fontSize: 8 },
    headStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },
    didDrawCell: function (data) {
      if (data.column.index === 15 && data.cell.section === "body") {
        var textPos = data.cell;
        if (data.row.raw[12]) {
          const step = props.ApprovalStep.filter(
            (x) => x.ForDev && x.ForDev.SubjectName === data.row.raw[12].content
          )[0];
          doc.addImage(
            valueTransform(step?.SignatureImage),
            "JPEG",
            textPos.x + 2,
            textPos.y + 1,
            valueTransform(step?.SignatureDimension?.width),
            5,
            `surenagix-${data.column.index}-${data.row.index}`,
            "NONE",
            0
          );
        }
      }
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      1: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      2: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      3: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      4: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      5: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      6: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      7: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      8: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      9: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      10: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      11: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      12: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      13: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      14: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      15: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      16: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
    },
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },

    head: [
      [
        {
          content: "ลำดับ\nNO.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "รหัสวิชา\nCOURSE CODE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
          colSpan: 8,
        },
        {
          content: "ประเภทการ\nลงทะเบียน\nTYPE OF\nREGIS.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "หมู่เรียน/SECTION",
          styles: { halign: "center", valign: "middle" },
          colSpan: 2,
        },
        {
          content: "ชื่อวิชา(เขียนเป็นภาษาอังกฤษ)\nCOURSE TITLE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "จำนวนหน่วยกิต\nCREDITS",
          styles: { halign: "center", valign: "middle" },
          colSpan: 2,
        },
        {
          content: "ลงนามอาจารย์\nประจำวิชา\nINSTRUCTURE's\nSIGNATURE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
      ],
      [
        {
          content: "บรรยาย\nLECTURE",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ปฏิบัติการ\nLAB",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "บรรยาย\nLECTURE",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ปฏิบัติการ\nLAB",
          styles: { halign: "center", valign: "middle" },
        },
      ],
    ],
    body: subjectDetailEnroll,
  });

  // Table section 2
  thaitext(doc, "ส่วนที่ 2 ของดรายวิชา/PART II: DROP COURSES", 5, 177);

  doc.autoTable({
    margin: { left: 5, top: 5, right: 5, bottom: 0 },
    startY: 181,
    styles: { font: "Sarabun-Regular", fontSize: 8 },
    headStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },
    didDrawCell: function (data) {
      if (data.column.index === 15 && data.cell.section === "body") {
        var textPos = data.cell;
        if (data.row.raw[12]) {
          const step = props.ApprovalStep.filter(
            (x) => x.ForDev && x.ForDev.SubjectName === data.row.raw[12].content
          )[0];
          doc.addImage(
            valueTransform(step?.SignatureImage),
            "JPEG",
            textPos.x + 2,
            textPos.y + 1,
            valueTransform(step?.SignatureDimension?.width),
            5,
            `surenagix-${data.column.index}-${data.row.index}`,
            "NONE",
            0
          );
        }
      }
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      1: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      2: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      3: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      4: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      5: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      6: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      7: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      8: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      9: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      10: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      11: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      12: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      13: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      14: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      15: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      16: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
    },
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },

    head: [
      [
        {
          content: "ลำดับ\nNO.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "รหัสวิชา\nCOURSE CODE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
          colSpan: 8,
        },
        {
          content: "ประเภทการ\nลงทะเบียน\nTYPE OF\nREGIS.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "หมู่เรียน/SECTION",
          styles: { halign: "center", valign: "middle" },
          colSpan: 2,
        },
        {
          content: "ชื่อวิชา(เขียนเป็นภาษาอังกฤษ)\nCOURSE TITLE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "จำนวนหน่วยกิต\nCREDITS",
          styles: { halign: "center", valign: "middle" },
          colSpan: 2,
        },
        {
          content: "ลงนามอาจารย์\nประจำวิชา\nINSTRUCTURE's\nSIGNATURE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
      ],
      [
        {
          content: "บรรยาย\nLECTURE",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ปฏิบัติการ\nLAB",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "บรรยาย\nLECTURE",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ปฏิบัติการ\nLAB",
          styles: { halign: "center", valign: "middle" },
        },
      ],
    ],
    body: subjectDetailWithdraw,
  });

  thaitext(
    doc,
    "สรุปการลงทะเบียนหลังการ เพิ่ม-ถอน/TOTAL CREDITS AFTER ADDED-DROPPED_________________________________________________หน่วยกิต/CREDITS",
    5,
    271
  );
  doc.setFontSize(8);
  thaitext(doc, "่", 112.5, 98);
  thaitext(doc, "่", 112.8, 189);

  doc.setFontSize(10);

  thaitext(doc, valueTransform(props.Data?.form_summary_credit), 150, 271);

  doc.text(
    "ลงนามนิสิต/STUDENT'S SIGNATURE_________________________________________ลงนามอาจารย์/ADVISOR'S SIGNATURE____________________________________",
    5,
    280
  );

  doc.addImage(
    valueTransform(props.ApprovalStep[0]?.SignatureImage),
    "JPEG",
    70,
    275,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature",
    "NONE",
    0
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureImage
    ),
    "JPEG",
    175,
    275,
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-teacher-1111",
    "NONE",
    0
  );

  thaitext(
    doc,
    "วันที่/DATE_________________________________________",
    43,
    290
  );
  thaitext(doc, "วันที่/DATE____________________________________", 147, 290);

  thaitext(
    doc,
    dateSignature(GetApprovalByPositionName(props, "นิสิต")?.DatetimeCreated),
    67,
    289
  );

  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.DatetimeUpdated
    ),
    165,
    289
  );
  // page 2  , General form

  // doc.addPage();

  // doc.addFont(sarabunFont, "Sarabun", "normal");
  // doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  // doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  // doc.addFont(sarabunLight, "Sarabun-Light", "normal");
  // doc.setFont("Sarabun-Medium");
  // doc.setFontSize(15);
  // doc.addImage(
  //   kuLogo,
  //   "JPEG",
  //   doc.internal.pageSize.getWidth() / 2 - 12,
  //   5,
  //   25,
  //   25,
  //   0,
  //   "NONE",
  //   0
  // );
  // doc.text(
  //   "มหาวิทยาลัยเกษตรศาสตร์",
  //   doc.internal.pageSize.getWidth() / 2 - 25,
  //   40
  // );
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(12);
  // thaitext(doc, "คำร้องทั่วไป", doc.internal.pageSize.getWidth() / 2 - 10, 47);

  // //Righr QRcode
  // doc.addImage(
  //   qrCode,
  //   "JPEG",
  //   doc.internal.pageSize.getWidth() / 2 + 70,
  //   5,
  //   30,
  //   30,
  //   "qrCode",
  //   "NONE",
  //   0
  // );
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(
  //   doc,
  //   "ตัวอย่างการเขียนคำร้อง",
  //   doc.internal.pageSize.getWidth() / 2 + 70,
  //   40
  // );
  // //End Right QRcode

  // // Contect
  // doc.setFontSize(10);
  // thaitext(
  //   doc,
  //   `วันที่.............เดือน....................................พ.ศ................`,
  //   doc.internal.pageSize.getWidth() / 2 - 35,
  //   54
  // );
  // thaitext(
  //   doc,
  //   thaiDate(props.DatetimeCreated).day,
  //   doc.internal.pageSize.getWidth() / 2 - 25,
  //   53
  // );
  // thaitext(
  //   doc,
  //   thaiDate(props.DatetimeCreated).month,
  //   doc.internal.pageSize.getWidth() / 2,
  //   53
  // );
  // thaitext(doc, thaiDate(props.DatetimeCreated).year, 137, 53);
  // thaitext(doc, `เรื่อง.........................................`, 10, 65);
  // thaitext(doc, valueTransform(props.Data.form_topic), 20, 64);
  // thaitext(
  //   doc,
  //   "เรียน.................................................................",
  //   10,
  //   73
  // );
  // thaitext(
  //   doc,
  //   valueTransform(
  //     `${props.DataUser.ReportTo.ThaiPrename} ${props.DataUser.ReportTo.FirstnameTh} ${props.DataUser.ReportTo.LastnameTh}`
  //   ),
  //   20,
  //   72
  // );
  // doc.setFontSize(9);
  // doc.setFont("Sarabun-Light");
  // thaitext(doc, "(อาจารย์ที่ปรึกษา)", 25, 78);

  // doc.setFontSize(10);
  // doc.setFont("Sarabun-Regular");
  // thaitext(
  //   doc,
  //   "ข้าพเจ้า  นาย/นาง/นางสาว..................................................................................เลขประจำตัว.................................. นิสิตปีที่ .............",
  //   20,
  //   85
  // );
  // thaitext(
  //   doc,
  //   ` ${valueTransform(props.DataUser.FirstnameTh)}   ${valueTransform(
  //     props.DataUser.LastnameTh
  //   )}`,
  //   65,
  //   84
  // );

  // thaitext(doc, ` ${valueTransform(props.DataUser.Code)}`, 155, 84);

  // thaitext(doc, ` ${valueTransform(props.DataUser.AcademicYear)}`, 195, 84);

  // thaitext(
  //   doc,
  //   "สาขาวิชาเอก.................................................................................................คณะ..............................................................................................",
  //   10,
  //   92
  // );

  // thaitext(doc, valueTransform(props.DataUser.Department), 35, 91);
  // thaitext(doc, valueTransform(props.DataUser.Faculty), 125, 91);

  // thaitext(
  //   doc,
  //   "ที่อยู่ปัจจุบัน........................................................................................................................................................................................................",
  //   10,
  //   99
  // );

  // thaitext(
  //   doc,
  //   valueTransform(props.DataUser.CurrentAddress.HomeNo) +
  //     " " +
  //     valueTransform(props.DataUser.CurrentAddress.Soi) +
  //     " " +
  //     valueTransform(props.DataUser.CurrentAddress.VillageNo) +
  //     " " +
  //     valueTransform(props.DataUser.CurrentAddress.City) +
  //     " " +
  //     valueTransform(props.DataUser.CurrentAddress.Province) +
  //     " " +
  //     valueTransform(props.DataUser.CurrentAddress.Country) +
  //     " " +
  //     valueTransform(props.DataUser.CurrentAddress.Zipcode),
  //   35,
  //   98
  // );

  // thaitext(
  //   doc,
  //   "โทรศัพท์........................................................................................E-mail..........................................................................................................",
  //   10,
  //   106
  // );

  // thaitext(doc, valueTransform(props.DataUser.Phonenumber), 30, 105);
  // thaitext(doc, valueTransform(props.DataUser.Email), 120, 105);

  // thaitext(doc, "ความประสงค์ (ระบุความประสงค์และเหตุผล)", 10, 113);
  // thaitext(doc, valueTransform(props.Data.form_reason), 10, 119);

  // thaitext(
  //   doc,
  //   "...........................................................................................................................................................................................................................",
  //   10,
  //   120
  // );

  // thaitext(
  //   doc,
  //   "...........................................................................................................................................................................................................................",
  //   10,
  //   127
  // );

  // thaitext(
  //   doc,
  //   "...........................................................................................................................................................................................................................",
  //   10,
  //   134
  // );

  // thaitext(doc, "จึงเรียนมาเพื่อโปรดพิจารณา", 150, 141);
  // thaitext(
  //   doc,
  //   "ลงนาม....................................................",
  //   140,
  //   148
  // );
  // thaitext(doc, "(                                           )", 150, 155);

  // doc.addImage(
  //   valueTransform(props.ApprovalStep[0]?.SignatureImage),
  //   "JPEG",
  //   165,
  //   142,
  //   valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
  //   5,
  //   "signature",
  //   "NONE",
  //   0
  // );

  // thaitext(
  //   doc,
  //   valueTransform(props.DataUser.FirstnameTh) +
  //     " " +
  //     valueTransform(props.DataUser.LastnameTh),
  //   152,
  //   155
  // );
  // doc.setFont("Sarabun-Light");
  // doc.setDrawColor(0, 0, 0);
  // doc.rect(10, 161, 95.25, 43);
  // doc.rect(105.25, 161, 95.25, 43);
  // doc.rect(10, 204, 95.25, 43);
  // doc.rect(105.25, 204, 95.25, 43);

  // // Start box 1
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(doc, "1. เรียน หัวหน้าภาคฯ / ประธานบริหารหลักสูตร", 15, 166);
  // thaitext(
  //   doc,
  //   valueTransform(
  //     GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.Comment
  //   ),
  //   20,
  //   175
  // );
  // thaitext(
  //   doc,
  //   "...................................................................................",
  //   20,
  //   176
  // );
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);

  // thaitext(
  //   doc,
  //   "ลงนาม/Signature...........................................................",
  //   30,
  //   187
  // );
  // doc.addImage(
  //   valueTransform(
  //     GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureImage
  //   ),
  //   "JPEG",
  //   71,
  //   181,
  //   valueTransform(
  //     GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureDimension
  //       ?.width
  //   ),
  //   5,
  //   "signature-step-1",
  //   "NONE",
  //   0
  // );
  // thaitext(doc, "(.................................................)", 55, 192);
  // thaitext(doc, fullNameTransform(props, "advisor"), 58, 191);
  // thaitext(doc, "........./.........../............", 65, 197);
  // thaitext(doc, "อาจารย์ที่ปรึกษา", 65, 202);

  // thaitext(
  //   doc,
  //   dateSignature(
  //     GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.DatetimeUpdated
  //   ),
  //   65,
  //   196
  // );
  // // End Box 1

  // // Start Box 2
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(doc, "2. เรียน คณบดี", 15, 209);
  // thaitext(
  //   doc,
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "chiefSyllabus")?.Comment
  //   ),
  //   20,
  //   218
  // );
  // thaitext(
  //   doc,
  //   "...................................................................................",
  //   20,
  //   219
  // );
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(
  //   doc,
  //   "ลงนาม/Signature...........................................................",
  //   30,
  //   230
  // );
  // doc.addImage(
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "chiefSyllabus")
  //       ?.SignatureImage
  //   ),
  //   "JPEG",
  //   70,
  //   225,
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "chiefSyllabus")
  //       ?.SignatureDimension?.width
  //   ),
  //   5,
  //   "signature-step-2",
  //   "NONE",
  //   0
  // );
  // thaitext(doc, "(.................................................)", 55, 235);

  // thaitext(
  //   doc,
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "chiefSyllabus")?.ForDev
  //       ?.Major?.Chief
  //   ),
  //   58,
  //   234
  // );
  // thaitext(doc, "........./.........../............", 65, 240);
  // thaitext(
  //   doc,
  //   dateSignature(
  //     GetApprovalByPositionCode(props, "chiefSyllabus")
  //       ?.DatetimeUpdated
  //   ),
  //   65,
  //   239
  // );
  // thaitext(doc, "หัวหน้าภาคฯ / ประธานบริหารหลักสูตร", 52, 245);
  // // End Box 2

  // // Start Box 3
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(doc, "3. เรียน คณบดี", 110, 166);
  // thaitext(
  //   doc,
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "studyOfficer")?.Comment
  //   ),
  //   115,
  //   175
  // );
  // thaitext(
  //   doc,
  //   "...................................................................................",
  //   115,
  //   176
  // );
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(
  //   doc,
  //   "ลงนาม/Signature...........................................................",
  //   125,
  //   187
  // );
  // doc.addImage(
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "studyOfficer")
  //       ?.SignatureImage
  //   ),
  //   "JPEG",
  //   170,
  //   181,
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "studyOfficer")
  //       ?.SignatureDimension?.width
  //   ),
  //   5,
  //   "signature-step-3",
  //   "NONE",
  //   0
  // );
  // // doc.addImage(
  // //   valueTransform(props.ApprovalStep[2]),
  // //   "JPEG",
  // //   170,
  // //   182,
  // //   5,
  // //   3,
  // //   "signature-step-3",
  // //   "NONE",
  // //   0
  // // );
  // thaitext(
  //   doc,
  //   "(.................................................)",
  //   150,
  //   192
  // );
  // thaitext(
  //   doc,
  //   valueTransform(
  //     GetApprovalByPositionCode(props, "studyOfficer")?.ForDev
  //       ?.ForDev?.ApprovedBy
  //   ),
  //   155,
  //   191
  // );
  // thaitext(doc, "........./.........../............", 158, 197);
  // thaitext(
  //   doc,
  //   dateSignature(
  //     GetApprovalByPositionCode(props, "studyOfficer")
  //       ?.DatetimeUpdated
  //   ),
  //   158,
  //   196
  // );
  // thaitext(doc, "studyOfficer", 155, 202);
  // // End Box 3

  // // Start Box 4
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(
  //   doc,
  //   `4. ${valueTransform(
  //     GetApprovalByPositionName(props, "คณบดี")?.ForDev?.AgendaForDean
  //   )}`,
  //   110,
  //   209
  // );
  // doc.setFont("Sarabun-Light");
  // thaitext(
  //   doc,
  //   valueTransform(GetApprovalByPositionName(props, "คณบดี")?.Comment),
  //   115,
  //   218
  // );
  // thaitext(
  //   doc,
  //   "...................................................................................",
  //   115,
  //   219
  // );
  // doc.setFont("Sarabun-Regular");
  // doc.setFontSize(9);
  // thaitext(
  //   doc,
  //   "ลงนาม/Signature...........................................................",
  //   125,
  //   230
  // );

  // doc.addImage(
  //   valueTransform(GetApprovalByPositionName(props, "คณบดี")?.SignatureImage),
  //   "JPEG",
  //   168,
  //   225,
  //   valueTransform(
  //     GetApprovalByPositionName(props, "คณบดี")?.SignatureDimension?.width
  //   ),
  //   5,
  //   "signature-step-4",
  //   "NONE",
  //   0
  // );
  // thaitext(
  //   doc,
  //   "(.................................................)",
  //   150,
  //   235
  // );
  // thaitext(
  //   doc,
  //   valueTransform(
  //     GetApprovalByPositionName(props, "คณบดี")?.ForDev?.Dean?.Chief
  //   ),
  //   154,
  //   234
  // );
  // thaitext(doc, "........./.........../............", 160, 240);
  // thaitext(
  //   doc,
  //   dateSignature(GetApprovalByPositionName(props, "คณบดี")?.DatetimeUpdated),
  //   160,
  //   239
  // );
  // thaitext(doc, "คณบดี", 168, 245);

  doc.output("dataurlnewwindow");
  // doc.save("form1.pdf");
};
