import "jspdf-autotable";

import {
  GetApprovalByPositionCode,
  GetApprovalByPositionName,
  addressTransform,
  fullNameTransform,
  valueTransform,
} from "../../util/ValueTransform";
import { thaitext, thaitextCenter, thaitextReason } from "../../util/Thaitext";

import checkMark from "../../assets/image/checkmark.png";
import { dateSignature } from "../../util/DateSignature";
import jsPDF from "jspdf";
import kuLogo from "../../assets/image/logo/ku_logo.jpeg";
import qrCode from "../../assets/image/qrcode/form-example.jpg";
import sarabunFont from "../../assets/fonts/sarabun/THSarabunNew.ttf";
import sarabunFontMedium from "../../assets/fonts/sarabun/Sarabun-Medium.ttf";
import sarabunLight from "../../assets/fonts/sarabun/Sarabun-Light.ttf";
import sarabunRegular from "../../assets/fonts/sarabun/Sarabun-Regular.ttf";
import { thaiDate } from "../../util/DateObject";

export const Form7 = (props) => {
  const doc = new jsPDF({
    orientation: "p",
    format: "a4",
  });

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.addFont(sarabunLight, "Sarabun-Light", "normal");

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(8);
  // Start Top Square
  doc.rect(157, 5, 43, 25, "S");
  thaitext(doc, "สำนักทะเบียนและประมวลผล", 162, 10);
  thaitext(doc, "เลขที่  ........................................", 160, 17);
  thaitext(doc, "วันที่  .........................................", 160, 24);
  thaitext(doc, "วันที่  ......../................./.............", 159, 37);
  const created = thaiDate(props.DatetimeCreated);
  thaitext(
    doc,
    created.day + "      " + created.month + "    " + created.year,
    167,
    36
  );
  doc.setFontSize(8);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Date                        DD/MM/YY", 159, 42);
  // End Top Square

  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.addImage(kuLogo, "JPEG", 10, 5, 25, 25, 0, "NONE", 0);
  doc.text("มหาวิทยาลัยเกษตรศาสตร์", 40, 15);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(12);
  doc.text("ใบขอลาพักการศึกษา /Request for Leave of Absence Request", 40, 23);
  doc.setFontSize(10);
  doc.text("เรียน.................................................", 10, 36);
  thaitext(doc, fullNameTransform(props, "advisor"), 25, 35);
  doc.setFont("Sarabun-Light");
  doc.text("To           (อาจารย์ที่ปรึกษา/Advisor)", 10, 41);
  doc.setFont("Sarabun-Regular");
  thaitext(
    doc,
    "ชื่อนิสิต..........................................................................................................................................................................ตัวบรรจง",
    23,
    50
  );

  thaitext(doc, fullNameTransform(props), 38, 49);

  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  doc.text("Student’s name", 23, 55);
  doc.text("(Print name)", 183, 55);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  doc.text("เลขประจำตัวนิสิต", 10, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[0]), 42, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[1]), 48, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[2]), 54, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[3]), 60, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[4]), 66, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[5]), 72, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[6]), 78, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[7]), 84, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[8]), 90, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[9]), 96, 63);
  doc.rect(40, 58, 6, 8, "S");
  doc.rect(46, 58, 6, 8, "S");
  doc.rect(52, 58, 6, 8, "S");
  doc.rect(58, 58, 6, 8, "S");
  doc.rect(64, 58, 6, 8, "S");
  doc.rect(70, 58, 6, 8, "S");
  doc.rect(76, 58, 6, 8, "S");
  doc.rect(82, 58, 6, 8, "S");
  doc.rect(88, 58, 6, 8, "S");
  doc.rect(94, 58, 6, 8, "S");
  thaitext(
    doc,
    "นิสิตชั้นปีที่..............คณะ......................................................................",
    102,
    63
  );
  thaitext(
    doc,
    valueTransform(props.DataUser.AcademicYear).toString(),
    122,
    62
  );
  thaitext(doc, valueTransform(props.DataUser.Faculty), 145, 62);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(doc, "Student ID Number", 10, 68);
  thaitext(doc, "Academic Level", 100, 68);
  thaitext(doc, "Faculty", 128, 68);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "สาขาวิชา..................................................................................หมายเลขโทรศัพท์...........................................ที่อยู่ปัจจุบัน..........................",
    10,
    76
  );
  thaitext(doc, valueTransform(props.DataUser.Major && props.DataUser.Major !== "" ? props.DataUser.Major : props.DataUser.Department), 30, 75);
  thaitext(doc, valueTransform(props.DataUser.Phonenumber), 128, 75);

  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(
    doc,
    "Major Field                                                                                              Phone number                                                  Current address",
    10,
    80
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "......................................................................................................................................................................................................................",
    10,
    86
  );
  thaitext(doc, addressTransform(props), 30, 85);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "สาเหตุที่ลา.....................................................................................................................................................................................................",
    10,
    92
  );
  thaitext(doc, valueTransform(props.Data.form_reason), 30, 91);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(doc, "Reason for request", 10, 96);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "มีความประสงค์ขอลาพักการศึกษาเป็นจำนวน.......ภาคการศึกษา ตั้งแต่.....................ปีการศึกษา.............ถึง.....................ปีการศึกษา............",
    10,
    104
  );

  thaitext(doc, valueTransform(props.Data.form_purpose), 75, 103);
  thaitext(doc, valueTransform(props.Data.form_purpose_1), 110, 103);
  thaitext(doc, valueTransform(props.Data.form_purpose_2), 144, 103);
  thaitext(doc, valueTransform(props.Data.form_purpose_1), 159, 103);
  thaitext(doc, valueTransform(props.Data.form_purpose_2), 193, 103);

  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(
    doc,
    "Request for leave of absence for                                    Semesters          from Semester    Academic year          to Semesters    Academic year",
    10,
    108
  );

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "อนึ่ง ข้าพเจ้าได้ลงทะเบียนเรียนไว้ในภาค.......................ปีการศึกษา.............ดังนี้",
    10,
    116
  );
  thaitext(doc, valueTransform(props.Data.form_purpose_1), 70, 115);
  thaitext(doc, valueTransform(props.Data.form_purpose_2), 104, 115);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  doc.text(
    "I am registered in the present semester for the following courses:",
    10,
    120
  );

  // Start center Table
  doc.autoTable({
    margin: { left: 10, top: 125, right: 5, bottom: 0 },
    styles: { font: "Sarabun-Regular", fontSize: 10 },
    tableWidth: 92.5,
    headerStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      1: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
    },
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },

    head: [
      [
        {
          content: "รหัสวิชา\nCourse code",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ชืออาจารย์ประจำวิชา\nLecturer",
          styles: { halign: "center", valign: "middle" },
        },
      ],
    ],
    body: [
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
    ],
  });
  thaitext(doc, "'", 60.5, 127.5);

  doc.autoTable({
    margin: { left: 107.5, top: 125, right: 5, bottom: 0 },
    startY: 125,
    styles: { font: "Sarabun-Regular", fontSize: 10 },
    tableWidth: 92.5,
    headerStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      1: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
    },
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },

    head: [
      [
        {
          content: "รหัสวิชา\nCourse code",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ชื่ออาจารย์ประจำวิชา\nLecturer",
          styles: { halign: "center", valign: "middle" },
        },
      ],
    ],
    body: [
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
    ],
  });
  thaitext(doc, "'", 158.2, 127.5);

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(doc, "จึงเรียนมาเพื่อโปรดพิจารณา", 35, 180);

  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  doc.text("Request for consideration", 37, 185);

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "ลงนามนิสิต/ผู้ดำเนินการแทน...................................................................",
    100,
    190
  );
  doc.addImage(
    props.ApprovalStep[0] ? props.ApprovalStep[0].SignatureImage : "",
    "JPEG",
    168,
    185,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature-step-self",
    "NONE",
    0
  );
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  doc.text("Student/Person Requesting Signature", 100, 195);

  // End center Table
  doc.setDrawColor(0, 0, 0);
  doc.rect(10, 200, 95.25, 43, "S");
  doc.rect(105.25, 200, 95.25, 43);
  doc.rect(10, 243, 95.25, 43);
  doc.rect(105.25, 243, 95.25, 43);

  // text in boxes

  // Start Box 1
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "1. เรียน หัวหน้าภาค", 15, 204);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To   Head of department", 19, 208);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "อนุมัติ", 30, 214);
  doc.rect(25, 211, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Approve", 39, 214);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่อนุมัติ", 30, 220);
  doc.rect(25, 217, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Denied", 42, 220);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    225
  );
  doc.addImage(
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureImage
    ),
    "JPEG",
    68,
    219,
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-1",
    "NONE",
    0
  );

  if (
    GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.Action === "APPROVED"
  ) {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      209,
      5,
      5,
      "signature-step-checkmark1",
      "NONE",
      0
    );
  } else if (
    GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.Action === "REJECTED"
  ) {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      215,
      5,
      5,
      "signature-step-checkmark1",
      "NONE",
      0
    );
  }
  thaitext(doc, "(.................................................)", 55, 230);
  thaitext(doc, fullNameTransform(props, "advisor"), 62, 229);
  thaitext(doc, "........./.........../..............", 60, 235);
  thaitext(doc, dateSignature(GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.DatetimeUpdated ?? GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.DatetimeCreated), 60, 234);

  thaitext(doc, "อาจารย์ที่ปรึกษา", 62, 240);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Advisor", 85, 240);
  // End Box 1

  // Start Box 2
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "2. เรียน คณบดี", 110, 204);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To   Dean", 114, 208);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "อนุมัติ", 125, 214);
  doc.rect(120, 211, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Approve", 134, 214);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่อนุมัติ", 125, 220);
  doc.rect(120, 217, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Denied", 137, 220);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    225
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")
        ?.SignatureImage
    ),
    "JPEG",
    165,
    220,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")
        ?.SignatureDimension?.width
    ),
    5,
    "signature-step-2",
    "NONE",
    0
  );

  if (
    GetApprovalByPositionCode(props, "chiefSyllabus")?.Action ===
    "APPROVED"
  ) {
    doc.addImage(
      checkMark,
      "PNG",
      120,
      209,
      5,
      5,
      "signature-step-checkmark33",
      "NONE",
      0
    );
  } else if (
    GetApprovalByPositionCode(props, "chiefSyllabus")?.Action ===
    "REJECTED"
  ) {
    doc.addImage(
      checkMark,
      "PNG",
      120,
      215,
      5,
      5,
      "signature-step-checkmark33",
      "NONE",
      0
    );
  }

  thaitext(
    doc,
    "(.................................................)",
    150,
    230
  );

  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")?.ForDev
        ?.Department?.Chief
    ),
    155,
    229
  );

  thaitext(doc, "........./............./...............", 158, 235);

  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionCode(props, "chiefSyllabus")
        ?.DatetimeUpdated
    ),
    160,
    234
  );
  thaitext(doc, "หัวหน้าภาควิชา", 147, 240);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Head of department", 168, 240);
  // End Box 2

  // Start Box 3
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "3. คำพิจารณาของคณบดีเจ้าสังกัด", 15, 247);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Dean’s decision", 19, 251);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "อนุมัติ", 30, 257);
  doc.rect(25, 254.5, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Approve", 39, 257);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่อนุมัติ", 30, 263);
  doc.rect(25, 260, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Denied", 42, 263);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    268
  );
  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, "คณบดี")?.SignatureImage),
    "JPEG",
    70,
    262,
    valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.SignatureDimension?.width
    ),
    5,
    "signature-step-3",
    "NONE",
    0
  );

  if (GetApprovalByPositionName(props, "คณบดี")?.Action === "APPROVED") {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      252,
      5,
      5,
      "signature-step-checkmark01",
      "NONE",
      0
    );
  } else if (GetApprovalByPositionName(props, "คณบดี")?.Action === "REJECTED") {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      258,
      5,
      5,
      "signature-step-checkmark02",
      "NONE",
      0
    );
  }

  thaitext(doc, "(.................................................)", 55, 273);
  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.ApprovalTemplate?.FullName
    ),
    60,
    272
  );

  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionName(props, "คณบดี")?.DatetimeUpdated ??
      GetApprovalByPositionName(props, "คณบดี")?.DatetimeCreated
    ),
    60,
    277
  );
  thaitext(doc, "........./............../...............", 58, 278);

  thaitext(doc, "คณบดี", 68, 283);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Dean", 77, 283);
  // End Box 3

  // Start Box 4
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "เรียน   ผู้อำนวยการสำนักบริหารการศึกษา", 110, 247);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To    Director of the Office of the Registrar", 110, 251);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "เพื่อโปรดดำเนินการ", 142, 257);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "to be processed", 144, 262);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    268
  );


  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, "คณบดี")?.SignatureImage),
    "JPEG",
    160,
    262,
    valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.SignatureDimension?.width
    ),
    5,
    "signature-step-4",
    "NONE",
    0
  );

  thaitext(
    doc,
    "(.................................................)",
    150,
    273
  );
  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.ApprovalTemplate?.FullName
    ),
    155,
    272
  );

  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionName(props, "คณบดี")?.DatetimeUpdated ??
      GetApprovalByPositionName(props, "คณบดี")?.DatetimeCreated
    ),
    160,
    277
  );
  thaitext(doc, "........./.........../..........", 160, 278);
  // thaitext(doc, "คณบดี", 163, 288);
  // doc.setFont("Sarabun-Light");
  // thaitext(doc, "Dean", 172, 288);
  // End Box 4

  thaitext(
    doc,
    "** ในกรณีที่ไม่ได้ลงทะเบียนเรียนในภาคการศึกษาที่ลาพัก จะต้องแนบหลักฐานการชำระค่าธรรมเนียมการศึกษาสำหรับภาคการศึกษาที่จะลาพักด้วย **",
    10,
    290
  );

  thaitext(
    doc,
    "** In the event of not having registered for the above courses, please provide evidence of tuition payment for said courses. **",
    10,
    294
  );

  // page 2  , General form

  doc.addPage();
  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.addFont(sarabunLight, "Sarabun-Light", "normal");
  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.addImage(
    kuLogo,
    "JPEG",
    doc.internal.pageSize.getWidth() / 2 - 12,
    5,
    25,
    25,
    0,
    "NONE",
    0
  );
  doc.text(
    "มหาวิทยาลัยเกษตรศาสตร์",
    doc.internal.pageSize.getWidth() / 2 - 25,
    40
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(12);
  thaitext(doc, "คำร้องทั่วไป", doc.internal.pageSize.getWidth() / 2 - 10, 47);

  //Righr QRcode
  doc.addImage(
    qrCode,
    "JPEG",
    doc.internal.pageSize.getWidth() / 2 + 70,
    5,
    30,
    30,
    "qrCode",
    "NONE",
    0
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ตัวอย่างการเขียนคำร้อง",
    doc.internal.pageSize.getWidth() / 2 + 70,
    40
  );
  //End Right QRcode

  // Contect
  doc.setFontSize(10);
  thaitext(
    doc,
    `วันที่.............เดือน....................................พ.ศ................`,
    doc.internal.pageSize.getWidth() / 2 - 35,
    54
  );
  thaitext(
    doc,
    thaiDate(props.DatetimeCreated).day,
    doc.internal.pageSize.getWidth() / 2 - 25,
    53
  );
  thaitext(
    doc,
    thaiDate(props.DatetimeCreated).month,
    doc.internal.pageSize.getWidth() / 2,
    53
  );
  thaitext(doc, thaiDate(props.DatetimeCreated).year, 137, 53);
  thaitext(doc, `เรื่อง.................................................................`, 10, 65);
  thaitext(doc, "รักษาสถานภาพนิสิต", 20, 64);
  thaitext(
    doc,
    "เรียน.................................................................",
    10,
    73
  );
  thaitext(doc, fullNameTransform(props, "advisor"), 20, 72);

  doc.setFontSize(9);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "(อาจารย์ที่ปรึกษา)", 25, 78);

  doc.setFontSize(10);
  doc.setFont("Sarabun-Regular");
  thaitext(
    doc,
    "ข้าพเจ้า.................................................................................................................เลขประจำตัว.................................. นิสิตปีที่ .............",
    20,
    85
  );
  thaitext(
    doc,
    `${valueTransform(props.DataUser.ThaiPrename)} ${valueTransform(props.DataUser.FirstnameTh)}   ${valueTransform(
      props.DataUser.LastnameTh
    )}`,
    38,
    84
  );

  thaitext(doc, ` ${valueTransform(props.DataUser.Code)}`, 155, 84);

  thaitext(doc, ` ${valueTransform(props.DataUser.AcademicYear)}`, 195, 84);

  thaitext(
    doc,
    "สาขาวิชาเอก.................................................................................................คณะ..............................................................................................",
    10,
    92
  );

  thaitext(doc, valueTransform(props.DataUser.Major && props.DataUser.Major !== "" ? props.DataUser.Major : props.DataUser.Department), 38, 91);
  thaitext(doc, valueTransform(props.DataUser.Faculty), 125, 91);

  thaitext(
    doc,
    "ที่อยู่ปัจจุบัน........................................................................................................................................................................................................",
    10,
    99
  );

  thaitext(
    doc,
    valueTransform(props.DataUser.CurrentAddress.HomeNo) +
    " " +
    valueTransform(props.DataUser.CurrentAddress.Soi) +
    " " +
    valueTransform(props.DataUser.CurrentAddress.VillageNo) +
    " " +
    valueTransform(props.DataUser.CurrentAddress.City) +
    " " +
    valueTransform(props.DataUser.CurrentAddress.Province) +
    " " +
    valueTransform(props.DataUser.CurrentAddress.Country) +
    " " +
    valueTransform(props.DataUser.CurrentAddress.Zipcode),
    38,
    98
  );

  thaitext(
    doc,
    "โทรศัพท์........................................................................................E-mail..........................................................................................................",
    10,
    106
  );

  thaitext(doc, valueTransform(props.DataUser.Phonenumber), 38, 105);
  thaitext(doc, valueTransform(props.DataUser.Email), 118, 105);

  thaitext(doc, "ความประสงค์ (ระบุความประสงค์และเหตุผล)", 10, 113);
  thaitextReason(doc, valueTransform(props.Data.form_reason), 20, 119);

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................",
    10,
    120
  );

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................",
    10,
    127
  );

  thaitext(
    doc,
    "...........................................................................................................................................................................................................................",
    10,
    134
  );

  thaitext(doc, "จึงเรียนมาเพื่อโปรดพิจารณา", 20, 141);
  thaitext(
    doc,
    "ลงนาม....................................................",
    140,
    148
  );
  thaitext(
    doc,
    "(                                                       )",
    150,
    155
  );

  doc.addImage(
    valueTransform(props.ApprovalStep[0]?.SignatureImage),
    "JPEG",
    165,
    142,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature",
    "NONE",
    0
  );

  thaitextCenter(
    doc,
    valueTransform(props.DataUser.ThaiPrename) + " " +
    valueTransform(props.DataUser.FirstnameTh) +
    " " +
    valueTransform(props.DataUser.LastnameTh),
    173,
    155
  );
  doc.setFont("Sarabun-Light");
  doc.rect(10, 161, 95.25, 43);
  doc.rect(105.25, 161, 95.25, 43);
  doc.rect(10, 204, 95.25, 43);
  doc.rect(105.25, 204, 95.25, 43);

  // Start box 1
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "1. เรียน หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ", 15, 166);
  thaitext(
    doc,
    "........................................................................................................",
    20,
    176
  );
  thaitext(doc, valueTransform(props.ApprovalStep[1]?.Comment), 25, 175);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    187
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureImage
    ),
    "JPEG",
    71,
    181,
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-17",
    "NONE",
    0
  );
  thaitext(doc, "(.................................................)", 55, 192);

  thaitext(doc, fullNameTransform(props, "advisor"), 60, 191);
  thaitext(doc, "........./.........../............", 65, 197);

  thaitext(doc, "อาจารย์ที่ปรึกษา", 65, 202);
  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.DatetimeUpdated
    ),
    65,
    196
  );

  // End Box 1

  // Start Box 2
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "2. เรียน คณบดี", 15, 209);
  thaitext(
    doc,
    "........................................................................................................",
    20,
    219
  );

  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")?.Comment
    ),
    25,
    218
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    230
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")
        ?.SignatureImage
    ),
    "JPEG",
    70,
    225,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")
        ?.SignatureDimension?.width
    ),
    5,
    "signature-step-23",
    "NONE",
    0
  );

  thaitext(doc, "(.................................................)", 55, 235);

  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "chiefSyllabus")?.ForDev
        ?.Major?.Chief ??
      GetApprovalByPositionCode(props, "chiefSyllabus")?.ForDev
        ?.Department?.Chief
    ),
    58,
    234
  );
  thaitext(doc, "........./.........../............", 65, 240);

  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionCode(props, "chiefSyllabus")
        ?.DatetimeUpdated
    ),
    65,
    239
  );
  thaitext(doc, "หัวหน้าภาคฯ / ประธานบริหาร", 52, 245);
  // End Box 2

  // Start Box 3
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "3. เรียน คณบดี", 110, 166);
  thaitext(
    doc,
    "........................................................................................................",
    115,
    176
  );
  thaitext(
    doc,
    valueTransform(GetApprovalByPositionCode(props, "studyOfficer")?.Comment),
    120,
    175
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    187
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionCode(props, "studyOfficer")?.SignatureImage
    ),
    "JPEG",
    170,
    181,
    valueTransform(
      GetApprovalByPositionCode(props, "studyOfficer")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-step-53",
    "NONE",
    0
  );

  thaitext(
    doc,
    "(.................................................)",
    150,
    192
  );

  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionCode(props, "studyOfficer")?.ForDev?.ApprovedBy
    ),
    155,
    191
  );
  thaitext(doc, "........./.........../............", 158, 197);
  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionCode(props, "studyOfficer")?.DatetimeUpdated
    ),
    158,
    196
  );
  thaitext(doc, "เจ้าหน้าที่ฝ่ายการศึกษา", 155, 202);
  // End Box 3

  // Start Box 4
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    `4. ${valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.ForDev?.AgendaForDean
    )}`,
    110,
    209
  );
  thaitext(
    doc,
    "........................................................................................................",
    115,
    219
  );
  thaitext(
    doc,
    valueTransform(GetApprovalByPositionName(props, "คณบดี")?.Comment),
    120,
    218
  );
  doc.setFont("Sarabun-Light");

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    230
  );
  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, "คณบดี")?.SignatureImage),
    "JPEG",
    168,
    225,
    valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.SignatureDimension?.width
    ),
    5,
    "signature-step-84",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    235
  );
  thaitext(
    doc,
    valueTransform(
      GetApprovalByPositionName(props, "คณบดี")?.ApprovalTemplate.FullName
    ),
    155,
    234
  );
  thaitext(doc, "........./.........../............", 160, 240);
  thaitext(
    doc,
    dateSignature(GetApprovalByPositionName(props, "คณบดี")?.DatetimeUpdated),
    160,
    239
  );
  thaitext(doc, "คณบดี", 168, 245);

  // ====================== new form ==========================================
  doc.addPage();
  //page 30

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.rect(5, 5, doc.internal.pageSize.width - 10, 25, "S");
  doc.addImage(kuLogo, "JPEG", 30, 8, 20, 20, 0, "NONE", 0);

  doc.text(
    "มหาวิทยาลัยเกษตรศาสตร์แบบลงทะเบียนเรียน (KU1)",
    doc.internal.pageSize.getWidth() / 2 + 5,
    15,
    { align: "center" }
  );

  doc.text(
    "KASETSART UNIVERSITY REGISTRATION FORM",
    doc.internal.pageSize.getWidth() / 2 + 5,
    23,
    { align: "center" }
  );

  doc.rect(5, 5, doc.internal.pageSize.width - 10, 35, "S");
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  doc.text(
    "ภาค/SEMESTER_________________________ปีการศึกษา/ACADEMIC YEAR___________________วิทยาเขต/CAMPUS_______________________________________",
    10,
    36
  );
  thaitext(doc, valueTransform(props.Data.form_purpose_1), 42, 36);
  thaitext(doc, valueTransform(props.Data.form_purpose_2), 112, 36);

  thaitext(doc, valueTransform("บางเขน"), 170, 36);
  doc.text("รหัสประจำตัวนิสิต/ID", 10, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[0]), 45, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[1]), 51, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[2]), 57, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[3]), 63, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[4]), 69, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[5]), 75, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[6]), 81, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[7]), 87, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[8]), 93, 47);
  thaitext(doc, valueTransform(props.DataUser.Code[9]), 100, 47);
  doc.rect(43, 42, 6, 8, "S");
  doc.rect(49, 42, 6, 8, "S");
  doc.rect(55, 42, 6, 8, "S");
  doc.rect(61, 42, 6, 8, "S");
  doc.rect(67, 42, 6, 8, "S");
  doc.rect(73, 42, 6, 8, "S");
  doc.rect(79, 42, 6, 8, "S");
  doc.rect(85, 42, 6, 8, "S");
  doc.rect(91, 42, 6, 8, "S");
  doc.rect(97, 42, 6, 8, "S");

  thaitext(
    doc,
    "ชื่อนิสิต/STUDENT'S NAME_____________________________________________________________เบอร์โทรศัพท์/PHONE NUMBER______________________________",
    10,
    57
  );
  thaitext(doc, fullNameTransform(props), 55, 57);
  thaitext(doc, valueTransform(props.DataUser.Phonenumber), 170, 57);
  doc.text(
    "คณะ/FACULTY______________ภาควิชา/DEPARTMENT_____________________________________________รหัสสาขาวิชาเอก/MAJOR CODE________________",
    10,
    65
  );
  thaitext(doc, valueTransform(props.DataUser.Faculty), 36, 65);
  thaitext(doc, valueTransform(props.DataUser.Major), 88, 65);
  thaitext(doc, valueTransform(props.DataUser.MajorCode), 183, 65);
  thaitext(
    doc,
    "ชื่ออาจารย์ที่ปรึกษา/ADVISOR'S NAME____________________________________________รหัสอาจารย์ที่ปรึกษา/ADVISOR'S CODE________________________",
    10,
    74
  );
  thaitext(doc, valueTransform(props.DataUser?.ReportTo?.Code), 180, 74);
  thaitext(doc, fullNameTransform(props, "advisor"), 75, 74);
  doc.rect(5, 5, doc.internal.pageSize.width - 10, 74, "S");

  thaitext(doc, "รายวิชาที่ขอลงทะเบียนเรียน/LIST OF COURSES REGISTER", 5, 86);

  doc.autoTable({
    margin: { left: 5, top: 90, right: 5, bottom: 0 },
    styles: { font: "Sarabun-Regular", fontSize: 8 },
    headerStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      1: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      2: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      3: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      4: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      5: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      6: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      7: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      8: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      9: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      10: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      11: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      12: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      13: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
      14: { halign: "center", valign: "middle", fillColor: [255, 255, 255] },
    },
    bodyStyles: {
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
      fillColor: "#ffffff",
      textColor: "#000000",
    },

    head: [
      [
        {
          content: "ลำดับ\nNO.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "รหัสวิชา\nCOURSE CODE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
          colSpan: 8,
        },
        {
          content: "ประเภทการ\nลงทะเบียน\nTYPE OF\nREGIS.",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "หมู่เรียน/SECTION",
          styles: { halign: "center", valign: "middle" },
          colSpan: 2,
        },
        {
          content: "ชื่อวิชา(เขียนเป็นภาษาอังกฤษ)/COURSE TITLE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "หน่วยกิต\nCREDITS",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
        {
          content: "ลงนามอาจารย์\nประจำวิชา\nINSTRUCTURE's\nSIGNATURE",
          styles: { halign: "center", valign: "middle" },
          rowSpan: 2,
        },
      ],
      [
        {
          content: "บรรยาย\nLECTURE",
          styles: { halign: "center", valign: "middle" },
        },
        {
          content: "ปฏิบัติการ\nLAB",
          styles: { halign: "center", valign: "middle" },
        },
      ],
    ],
    body: [
      [
        {
          content: "1",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: "0 ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: "รักษาสถานภาพนิสิต",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "2",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "3",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "4",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "5",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "6",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "7",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "8",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "9",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "10",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "11",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "12",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "13",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "14",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "15",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
        {
          content: " ",
        },
      ],
      [
        {
          content: "หน่วยกิตรวม/TOTAL CREDIT",
          colSpan: 13,
          styles: {
            halign: "right",
          },
        },
      ],
    ],
  });

  doc.setFontSize(7);
  thaitext(doc, "'", 113, 98.2);
  doc.setFontSize(10);
  doc.text(
    "ลงนามนิสิต/STUDENT'S SIGNATURE_________________________________________ลงนามอาจารย์/ADVISOR'S SIGNATURE____________________________________",
    5,
    280
  );

  doc.addImage(
    props.ApprovalStep[0] ? props.ApprovalStep[0].SignatureImage : "",
    "JPEG",
    70,
    275,
    props.ApprovalStep[0] ? props.ApprovalStep[0].SignatureDimension.width : "",
    5,
    "signature-step-7",
    "NONE",
    0
  );

  doc.addImage(
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureImage
    ),
    "JPEG",
    175,
    275,
    valueTransform(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.SignatureDimension
        ?.width
    ),
    5,
    "signature-teacher-8",
    "NONE",
    0
  );

  thaitext(
    doc,
    "วันที่/DATE_________________________________________",
    43,
    290 //260
  );
  thaitext(doc, "วันที่/DATE____________________________________", 147, 290); //260

  thaitext(
    doc,
    dateSignature(
      GetApprovalByPositionName(props, "อาจารย์ที่ปรึกษา")?.DatetimeUpdated
    ),
    165,
    290
  );

  thaitext(
    doc,
    dateSignature(
      props.ApprovalStep[0]?.DatetimeUpdated
        ? props.ApprovalStep[0]?.DatetimeUpdated
        : props.ApprovalStep[0]?.DatetimeCreated
    ),
    67,
    290
  );
  doc.output("dataurlnewwindow");
  // doc.save("form1.pdf");
};
