import { Button } from "antd";
import React from "react";
import './style.scss'

import LOGOAERICULTURE from '../../assets/image/logo/mocklogo.png'

export default function LoginTeacherPage() {

  // const [loadingBtnLogin, setLoadingBtnLogin] = useState(false);

  // function onSubmitLoginForm(dataForm) {
  //   setLoadingBtnLogin(true)
  //   console.log('dataForm', dataForm)
  // }


  return (
    <div className="wrap-login-page">
      <div className="wrap-login-content">
        <img src={LOGOAERICULTURE} alt="logo-agriculture"></img>
        <div className="wrap-label-login-page">
          <p>
          ระบบสารสนเทศสำหรับอาจารย์และบุคลากร 
          </p>
          <p>
            คณะเกษตร มหาวิทยาลัยเกษตรศาสตร์
          </p>
        </div>
        
        <div className="login-box-yellow"></div>

        <div className="wrap-login-form">
            <Button className="btn-login">Login</Button>
        </div>
      </div>
    </div>
  )
}
