import axios from "axios";
import { ENV, HTTP_STATUS_CODE } from "../../util/Constants";

const access_token = sessionStorage.getItem("access_token");

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    Authorization: `Bearer ${access_token}`,
  },
});

ApiClient.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("access_token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      error != null &&
      error.response != null &&
      error.response.status === 500
    ) {
      sessionStorage.removeItem("access_token");
      handleRefreshToken(error);
    }
    return Promise.reject(error);
  }
);

async function handleRefreshToken(error) {
  const originalRequest = error.config;
  if (!originalRequest._retry) {
    originalRequest._retry = true;
    console.log("handleRefreshToken");
    try {
      const refresh_token = sessionStorage.getItem("refresh_token");
      if (!refresh_token) {
        window.location = "/login";
      }
      const result = await axios.post(
        `${process.env.REACT_APP_API_HOST}/auth/refresh`,
        { refresh_token: refresh_token }
      );
      if (result && result.data && result.data.access_token) {
        sessionStorage.setItem("access_token", result.data.access_token);
      }
    } catch (error) {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("refresh_token");
      sessionStorage.removeItem("refresh_main");
      window.location = "/main";
    }
    return ApiClient(originalRequest);
  }else{
    window.location = "/login";
  }
}

export default ApiClient;
