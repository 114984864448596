import { Button } from "antd";
import React  from "react";
import { useNavigate } from "react-router-dom";
import "../style.scss"

export default function HTTP401Page(){
    const navigate = useNavigate()
    return (
        <div className="content-http-status-page">
            <p className="txt-status">401</p> 
            <p className="status-description">Missing Permissions</p>
            <Button className="btn-back-login"  onClick={() => { navigate(`/login`) }}>Back Login</Button>
        </div>
    )
}